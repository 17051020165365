import React from 'react';

const SubmitButton = ({ onClick , caption, color, buttonType, disabled}) => {

    const Class = `btn width-full ${color} round-edge}`;

    return (
        <button type={buttonType} onClick={onClick} className= {Class} disabled={disabled}>
        {caption}
        </button>
    );
};

export default SubmitButton;