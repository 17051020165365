import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../common/const";
import "../Login/Login.css";
import logo from "../../asset/img/logo.png";
import user_ico from "../../asset/img/user_login.png";
import password_ico from "../../asset/img/pass_login.png";
import { UserContext } from "../../context/UserContext";
import { notifySuccess, notifyError, notifyInfo } from "../Toaster";

const Login = () => {
  const [telephoneNo, setTelephoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [forgetPassword, setForgetPassword] = useState("");
  const [email, setEmail] = useState("");

  const handleLogin = async () => {
    if (!telephoneNo || !password) {
      notifyInfo("User Id and Password are required.");
      return;
    }

    // prevent multiple clicks
    if (loading) return;

    // set loading to True
    setLoading(true);

    try {
      const encodedPassword = btoa(password);
      const response = await fetch(`${apiUrl}/user/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          telephoneNo,
          password: encodedPassword,
        }),
      });
      if (response.ok) {
        const reqData = await response.json();
        setUser(reqData.result);
        navigate("/home");
      } else {
        notifyError("Invalid User ID or Password.");
      }
    } catch (error) {
      notifyError("Login failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };


  const handleSendEmail = async () => {
    if (!validateEmail(email)) {
      notifyError('Invalid email address');
      return;
    }
    
    try {
      const response = await fetch(`${apiUrl}/user/pwrecovery`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email
        }),
      });
      if (response.ok) {
        const reqData = await response.json();
        notifyInfo('Password recovery email sent. Please check your inbox.');
        setForgetPassword('');
        setEmail('');
      } else {
        notifyError('Error sending email. Please try again later.');
      }
    } catch (error) {
      notifyError('Error sending email. Please try again later.');
    } 
  };


  return (
    <>
      <div className="container h-100">
        <div className="d-flex justify-content-center h-100">
          <div className="user_card">
            <div className="d-flex justify-content-center">
              <div className="brand_logo_container">
                <img src={logo} className="brand_logo" alt="Logo" />
              </div>
            </div>
            <div className="d-flex justify-content-center form_container">
              <form>
                <div className="input-group mb-3">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fa">
                        <img
                          src={user_ico}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </i>
                    </span>
                  </div>
                  <input
                    type="text"
                    value={telephoneNo}
                    onChange={(e) => setTelephoneNo(e.target.value)}
                    className="form-control input_user"
                    required
                    placeholder="Telephone No"
                  />
                </div>
                <div className="input-group mb-2">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas">
                        <img
                          src={password_ico}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </i>
                    </span>
                  </div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="form-control input_pass"
                    placeholder="Password"
                  />
                </div>
                <div className="d-flex justify-content-center mt-3 login_container">
                  <button
                    type="button"
                    name="button"
                    className="btn login_btn"
                    onClick={handleLogin}
                    disabled={loading}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
            <div className="mt-4">
              {/*<div className="d-flex justify-content-center links font-b-12">
                Don't have an account?{" "}
                <a href="#" className="ml-2">
                  Sign Up
                </a>
              </div> */}
              <div className="d-flex justify-content-center links font-b-12">
                <span onClick={() => setForgetPassword("1")}>
                  Forgot Password?
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-center links font-b-12 mt-4">
              Supun Serivce
            </div>
            <div className="d-flex justify-content-center links font-b-12">
              <a href="/banner">Contact us</a>
            </div>
          </div>
        </div>
      </div>
      {/* fogot password email entering*/}

      {forgetPassword === "1" && (
        <div className="d-flex justify-content-center">
          <div className="user_card" style={{ height: "120px" }}>
            <input
              type="email"
              placeholder="Enter your email for recovery"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control input_user"
            />
            <div className="d-flex justify-content-center mt-3 login_container">
              <button
                type="button"
                className="btn login_btn"
                onClick={handleSendEmail}
              >
                Send Request
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
