import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { apiUrl } from "../../common/const";
import "../Login/Login.css";
import logo from "../../asset/img/logo.png";
import user_ico from "../../asset/img/user_login.png";
import password_ico from "../../asset/img/pass_login.png";
import { notifySuccess, notifyError, notifyInfo } from "../Toaster";

const ResetPassword = () => {
  const { token } = useParams(); // Get token from URL
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      notifyError("Passwords do not match");
      return;
    }

    try {
      const encodedPassword = btoa(newPassword);
      const response = await fetch(`${apiUrl}/user/resetpassword`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: token.replace("token=", ""),
          password: encodedPassword,
        }),
      });
      if (response.ok) {
        notifyInfo("Password updated successfully");
        setError("");
        setTimeout(() => navigate("/login"), 2000);
      } else {
        const error = await response.json(); 
        const {message} = error;
        if (message){
            notifyError(message);
        }else {
            notifyError("Error updating password. Please try again.");
        }
      }
    } catch (error) {
        const {message} = error;
        if (message){
            notifyError(message);
        }else {
            notifyError("Error updating password. Please try again.");
        }
    }
  };

  return (
    <div>
      <div className="container h-100">
        <div className="d-flex justify-content-center h-100">
          <div className="user_card" style={{ backgroundColor: "#b9cfe5" }}>
            <div className="d-flex justify-content-center">
              <div className="brand_logo_container">
                <img src={logo} className="brand_logo" alt="Logo" />
              </div>
            </div>
            <div className="d-flex justify-content-center form_container">
              <form onSubmit={handleSubmit}>
                <h4
                  style={{
                    color: "#c0392b",
                    textAlign: "center",
                    padding: "0px 0px 20px 0px ",
                  }}
                >
                  Resert Password
                </h4>
                <div className="input-group mb-3">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fa">
                        <img
                          src={password_ico}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </i>
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control input_user"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group mb-2">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas">
                        <img
                          src={password_ico}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </i>
                    </span>
                  </div>
                  <input
                    type="password"
                    required
                    className="form-control input_pass"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-center mt-3 login_container">
                  <button type="submit" name="button" className="btn login_btn">
                    Update Password
                  </button>
                </div>
                {error && (
                  <p className="font-r-12" style={{ color: "red" }}>
                    {error}
                  </p>
                )}
              </form>
            </div>
            <div className="d-flex justify-content-center links font-b-12">
              <a href="/banner">Contact us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
