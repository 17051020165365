// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */
.footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-links {
    margin-top: 10px;
  }
  
  .footer-links a {
    color: #fff;
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: #1e90ff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,sBAAsB;IACtB,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,SAAS;IACT,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,cAAc;IACd,qBAAqB;IACrB,sBAAsB;EACxB;;EAEA;IACE,cAAc;EAChB","sourcesContent":["/* Footer.css */\r\n.footer {\r\n    background-color: #333;\r\n    color: #fff;\r\n    padding: 20px 0;\r\n    text-align: center;\r\n    position: relative;\r\n    bottom: 0;\r\n    width: 100%;\r\n  }\r\n  \r\n  .footer-container {\r\n    max-width: 1200px;\r\n    margin: 0 auto;\r\n    padding: 0 20px;\r\n  }\r\n  \r\n  .footer-content {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n  \r\n  .footer-links {\r\n    margin-top: 10px;\r\n  }\r\n  \r\n  .footer-links a {\r\n    color: #fff;\r\n    margin: 0 15px;\r\n    text-decoration: none;\r\n    transition: color 0.3s;\r\n  }\r\n  \r\n  .footer-links a:hover {\r\n    color: #1e90ff;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
