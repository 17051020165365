import React, { useContext,useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate , useLocation} from "react-router-dom";
import Registration from "./User/Registration";

const User = () => {
  //////////////USER ///////////////////
  const { user } = useContext(UserContext);
  //////////////USER ///////////////////
  const location = useLocation();
  const { id, userId } = location.state || {};

  // ナヴィゲーションを生成
  const navigate = useNavigate();

  useEffect(() => {

    // Call the fetchData function
    if (!user) {
      navigate("/");
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts


  return <Registration userid={id?? user?._id?? null}></Registration>;
};
export default User;
