// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: solid 1px;
    border-color: rgb(0, 0, 0);
    height: 100px;
    width: 100%;
    color: black;
  }
  
  .image-container {
    padding: 10px 0px 0px 0px;
    border-radius: 50%;
  }
  
  .button-text {
    padding-top: 25px;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
  }`, "",{"version":3,"sources":["webpack://./src/components/ImageButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,iBAAiB;IACjB,0BAA0B;IAC1B,aAAa;IACb,WAAW;IACX,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":[".custom-button {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color: white;\r\n    border: solid 1px;\r\n    border-color: rgb(0, 0, 0);\r\n    height: 100px;\r\n    width: 100%;\r\n    color: black;\r\n  }\r\n  \r\n  .image-container {\r\n    padding: 10px 0px 0px 0px;\r\n    border-radius: 50%;\r\n  }\r\n  \r\n  .button-text {\r\n    padding-top: 25px;\r\n    font-size: 12px;\r\n    font-weight: normal;\r\n    text-align: center;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
