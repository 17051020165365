import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import SubmitButton from "../SubmitButton";
import {
  apiUrl,
  banks,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
} from "../../common/const";
import SubHeader from "../SubHeader";
import { notifySuccess, notifyError, notifyInfo } from "../Toaster";

function Registration(userid) {
  // get user parameter (id)
  const [searchParams] = useSearchParams();
  var id = searchParams.get("id");

  // userid はパラメータとしてuser iconクリックする時渡す
  id = userid?.userid ?? id;

  // ナヴィゲーションを生成
  const navigate = useNavigate();

  // saved data
  const [data, setData] = useState({});
  const [posting, setPosting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/user/reglink`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: id,
            flag: userid.userid ?? 0, // 0 - in registration, userid in profile > user
          }),
        });
        if (response.ok) {
          const reqData = await response.json();
          setData(reqData.result);
          setTxtTelephoneNo(reqData.result.telephoneNo);
          setAccounts(reqData.result.accounts?? [])
          setName(reqData.result.name);
          setGameId(reqData.result.gameId);
          setEmail(reqData.result.email);
          if ((reqData.result.gameId?? '') !== ''){
            setPassword('password');
            setConfirmPassword('password');
          }
        } else {
          const reqData = await response.json();
          const { message } = reqData;
          notifyError(message ?? MESSAGE_ERROR);
          navigate("/");
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
        navigate("/");
      } finally {
        setLoading(false);
      }
    };
    // Call the fetchData function
    if (!id) {
      navigate("/");
    }
    else {
    fetchData();
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const [gameId, setGameId] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [bank, setBank] = useState("");
  const [branch, setBranch] = useState("");
  const [txtTelephoneNo, setTxtTelephoneNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accounts, setAccounts] = useState([]);
  // エラーuseStateを設定
  const [errors, setErrors] = useState({});
  // psotbackエラー
  const [error, setError] = useState(null);

  const errorGameId = "Please enter the Game ID";
  const errorName = "Please enter the Name";
  const errorPassword = "Please enter the Password";
  const errorConfirmPassword = "Please enter the Confirm Password";
  const errorBank = "Please select the Bank";
  const errorBranch = "Please enter the Branch";
  const errorAccount = "Please enter the Account No";
  const errorTelephoneNo = "Please enter the Telephone No";
  const errorEmail = "Please enter the email";
  const errorAccountName = "Please enter the Account Name";
  const errorAccounts = "Please add the Bank details";

  const handleNameOnChange = (e) => {
    // 名前を設定
    setName(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorName;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, txtName: error }));
  };

  const handleGameIdOnChange = (e) => {
    // 名前を設定
    setGameId(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorGameId;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, txtGameId: error }));
  };

  const handlePasswordOnChange = (e) => {
    // 名前を設定
    setPassword(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorPassword;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, txtPassword: error }));
  };

  const handleConfirmPasswordOnChange = (e) => {
    // 名前を設定
    setConfirmPassword(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorConfirmPassword;
    }

    if (password !== e.target.value) {
      // エラーを付与
      error = "Confirm password is incorrect";
    }

    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, txtConfirmPassword: error }));
  };

  const handleAccountOnChange = (e) => {
    setAccountNo(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorAccount;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, txtAccount: error }));
  };

  const handleBankOnChange = (e) => {
    setBank(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorBank;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, txtBank: error }));
  };

  const handleBranchOnChange = (e) => {
    setBranch(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorBranch;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, txtBranch: error }));
  };

  const handleEmailOnChange = (e) => {
    setEmail(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorEmail;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, txtEmail: error }));
  };

  const handleAccountNameOnChange = (e) => {
    setAccountName(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorAccountName;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, txtAccountName: error }));
  };

  const completeValidate = () => {
    const newErrors = {};
    if (!gameId ) newErrors.txtGameId = errorGameId;
    if (!name) newErrors.txtName = errorName;
    if ((data?.name ?? "") === "") {
      if (!password) newErrors.txtPassword = errorPassword;
      if (!confirmPassword) newErrors.txtConfirmPassword = errorConfirmPassword;
    }
    if (!txtTelephoneNo) newErrors.txtTelephoneNo = errorTelephoneNo;
    if (!email) newErrors.txtEmail = errorEmail;
    if (accounts.length === 0) newErrors.txtAccounts = errorAccounts;
    return newErrors;
  };

  const completeValidateBank = () => {
    const newErrors = {};

    if (!accountNo) newErrors.txtAccount = errorAccount;
    if (!bank) newErrors.txtBank = errorBank;
    if (!accountName) newErrors.txtAccountName = errorAccountName;
    return newErrors;
  };

  const handleSubmit = async (e) => {
    // prevent multiple clicks
    if (posting) return;

    // Prevent default form submission
    e.preventDefault();
    // バリデーションを実行
    const validationErrors = completeValidate();
    //　エラーアレイを判断
    if (Object.keys(validationErrors).length > 0) {
      // エラーを設定
      setErrors(validationErrors);
    } else {
      // エラーをクリアする
      setErrors({});

      // set posting to True
      setPosting(true);

      try {
        const response = await fetch(`${apiUrl}/user/reg`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: id,
            name: name,
            gameId: gameId,
            password: password,
            accounts: accounts,
            email: email,
          }),
        });
        if (response.ok) {
          const reqData = await response.json();
          notifySuccess("User Registration is Successfully Completed.");
          if ((data?.name ?? "") === "") {
            navigate("/");
          }
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
        setLoading(false);
        setPosting(false);
      }
    }
  };

  const handleAddAccount = () => {
    // バリデーションを実行
    const validationErrors = completeValidateBank();
    //　エラーアレイを判断
    if (Object.keys(validationErrors).length > 0) {
      // エラーを設定
      setErrors(validationErrors);
    } else {
      // Create a new account object
      const newAccount = {
        accountName,
        accountNo,
        bank,
        bankName: banks.find((x) => x.key === bank).value,
        branch,
      };

      // Add new account to the list
      setAccounts([...accounts, newAccount]);

      // Clear input fields
      setAccountName("");
      setAccountNo("");
      setBranch("");
      setBank("");
    }
  };

  // Handle Delete button click
  const handleDeleteAccount = (index) => {
    // Filter out the account to be deleted by its index
    const updatedAccounts = accounts.filter((_, i) => i !== index);
    setAccounts(updatedAccounts);
  };

  if (loading) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-success" role="alert">
              Data is loading....
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="container body round-edge button-border">
        <div className="row text-left ">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <SubHeader title="User Registration "></SubHeader>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
            <span className="font-b-16 color-black">Telephone No</span>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <input
              type="text"
              className="font-r-16 width-half form-control"
              value={txtTelephoneNo}
              disabled={true}
            />
          </div>
          <div className="font-r-12 color-error">
            {errors.txtTelephoneNo && <span>{errors.txtTelephoneNo}</span>}
          </div>

          <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
            <span className="font-b-16 color-black">Name</span>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <input
              type="text"
              className="font-r-16 width-half form-control"
              value={name}
              onChange={handleNameOnChange}
              disabled={data.name}
            />
          </div>
          <div className="font-r-12 color-error">
            {errors.txtName && <span>{errors.txtName}</span>}
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
            <span className="font-b-16 color-black">Game Id</span>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <input
              type="text"
              className="font-r-16 width-half form-control"
              value={gameId}
              onChange={handleGameIdOnChange}
            />
          </div>
          <div className="font-r-12 color-error">
            {errors.txtGameId && <span>{errors.txtGameId}</span>}
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
            <span className="font-b-16 color-black">email</span>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <input
              type="email"
              className="font-r-16 width-half form-control"
              value={email}
              onChange={handleEmailOnChange}
            />
          </div>
          <div className="font-r-12 color-error">
            {errors.txtEmail && <span>{errors.txtEmail}</span>}
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
            <span className="font-b-16 color-black">Password</span>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <input
              type="password"
              className="font-r-16 width-half form-control"
              value={password}
              onChange={handlePasswordOnChange}
              disabled={data.name}
            />
          </div>
          <div className="font-r-12 color-error">
            {errors.txtPassword && <span>{errors.txtPassword}</span>}
          </div>

          <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
            <span className="font-b-16 color-black">Confirm Password</span>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <input
              type="password"
              className="font-r-16 width-half form-control"
              value={confirmPassword}
              onChange={handleConfirmPasswordOnChange}
              disabled={data.name}
            />
          </div>
          <div className="font-r-12 color-error mb-2">
            {errors.txtConfirmPassword && (
              <span>{errors.txtConfirmPassword}</span>
            )}
          </div>
          <div
            className="container body round-edge button-border p-2"
            style={{ width: "98%" }}
          >
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
              <span className="font-b-16 color-black">Account Name</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <input
                type="text"
                className="font-r-16 width-half form-control"
                value={accountName}
                onChange={handleAccountNameOnChange}
              />
            </div>
            <div className="font-r-12 color-error">
              {errors.txtAccountName && <span>{errors.txtAccountName}</span>}
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
              <span className="font-b-16 color-black">Account No</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <input
                type="text"
                className="font-r-16 width-half form-control"
                value={accountNo}
                onChange={handleAccountOnChange}
              />
            </div>
            <div className="font-r-12 color-error">
              {errors.txtAccount && <span>{errors.txtAccount}</span>}
            </div>

            <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
              <span className="font-b-16 color-black">Bank</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <select
                id="drpdepositType"
                className="form-select font-r-16 width-half py-1"
                value={bank}
                onChange={handleBankOnChange}
              >
                <option value="">Select an option</option>
                {banks.map((item, index) => (
                  <option value={item.key}>{item.value}</option>
                ))}
                ;
              </select>
              <div className="font-r-12 color-error">
                {errors.txtBank && <span>{errors.txtBank}</span>}
              </div>
            </div>

            <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
              <span className="font-b-16 color-black">Branch Name</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <input
                type="text"
                className="font-r-16 width-half form-control"
                value={branch}
                onChange={handleBranchOnChange}
              />
              <div className="font-r-12 color-error">
                {errors.txtBranch && <span>{errors.txtBranch}</span>}
              </div>
              <div className="font-r-12 color-error">
                {errors.txtAccounts && <span>{errors.txtAccounts}</span>}
              </div>
              <SubmitButton
                id="btnAdd"
                caption="Add Bank"
                buttonType="button"
                color="btn-outline-primary width-half"
                onClick={handleAddAccount}
              ></SubmitButton>
            </div>

            <div className="col-12 col-xs-12 col-sm-12 col-md-12 mt-3">
              <table className="table table-bordered font-r-14">
                <thead className="table-info">
                  <tr>
                    <th className=" bg-wheat">Account Name</th>
                    <th className=" bg-wheat">Account No</th>
                    <th className=" bg-wheat">Bank</th>
                    <th className=" bg-wheat">Branch</th>
                    <th className=" bg-wheat"></th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.map((account, index) => (
                    <tr key={index}>
                      <td>{account.accountName}</td>
                      <td>{account.accountNo}</td>
                      <td>{account.bankName}</td>
                      <td>{account.branch}</td>
                      <td>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDeleteAccount(index)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-4 col-xs-4 col-sm-4 col-md-4 pb-2">
            <SubmitButton
              id="btnRegister"
              caption="Register"
              buttonType="submit"
              color="btn-outline-success"
              disabled={posting}
            ></SubmitButton>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Registration;
