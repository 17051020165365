// Footer.jsx
import React from 'react';
import './Footer.css'; // Import the CSS file for styling
import { HOTLINE_NO } from "../common/const";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <p>&copy; 2024 Supun Service. All rights reserved.</p>
          <div className="footer-links">
            <a href="/banner">Contact Us</a>
            <span>Hotline : {HOTLINE_NO} </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
