var isDemo = 1; // 1: demo, 2: test, 3: live

export const depositTypes = [
  { key: "1", value: "Online" },
  { key: "2", value: "CDM" },
  { key: "3", value: "Binance" },
  { key: "4", value: "Other" },
];
 
export const apiUrl =
  process.env.REACT_APP_STATUS === '1'
    ? process.env.REACT_APP_DEMO_API_URL
    : process.env.REACT_APP_STATUS === '2'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_LIVE_URL;
export const socketUrl = 
  process.env.REACT_APP_STATUS === '1'
    ? process.env.REACT_APP_SOCKER_DEMO_API_URL
    : process.env.REACT_APP_STATUS === '2'
    ? process.env.REACT_APP_SOCKER_API_URL
    : process.env.REACT_APP_SOCKER_LIVE_API_URL;


export const depositStatusShort = ["", "N", "D", "R", "V"];
export const depositStatus = ["", "New", "Done", "Reject", "Verify"];

export const withdrawStatus = [
  "",
  "New",
  "Verify",
  "Confirm",
  "Re-deposit",
  "Complete",
  "Reject",
];
export const withdrawStatusShort = ["", "N", "V", "C", "E", "D", "R"];

export const depositTypesWithId = [
  { key: "", value: "All" },
  { key: "1", value: "New" },
  { key: "4", value: "Verify" },
  { key: "2", value: "Done" },
  { key: "3", value: "Reject" },
];

export const withdrawTypesWithId = [
  { key: "", value: "All" },
  { key: "1", value: "New" },
  { key: "2", value: "Verify" },
  { key: "3", value: "Confirm" },
  { key: "4", value: "Re-deposit" },
  { key: "5", value: "Complete" },
  { key: "6", value: "Reject" },
];

export const depositStatusColor = [
  "",
  "bg-new",
  "bg-done",
  "bg-reject",
  "bg-verify",
];
export const withdrawStatusColor = [
  "",
  "bg-new",
  "bg-verify",
  "bg-reject",
  "bg-verify",
  "bg-done",
  "bg-reject",
];

export const banks = [
  { key: "1", value: "Amana Bank" },
  { key: "2", value: "Bank of Ceylon" },
  { key: "3", value: "Cargills Bank" },
  { key: "4", value: "Central Finance Company" },
  { key: "5", value: "Citizens Development Business Finance" },
  { key: "6", value: "Commercial Bank of Ceylon" },
  { key: "7", value: "Commercial Credit and Finance" },
  { key: "8", value: "DFCC Bank" },
  { key: "9", value: "Dialog Finance" },
  { key: "10", value: "Hatton National Bank" },
  { key: "11", value: "HNB Finance" },
  { key: "12", value: "Hong Kong and Shanghai Banking Corporation (HSBC)" },
  {
    key: "13",
    value: "Housing Development Finance Corporation Bank of Sri Lanka (HDFC)",
  },
  { key: "14", value: "LB Finance" },
  { key: "15", value: "LOLC Finance" },
  { key: "16", value: "National Development Bank" },
  { key: "17", value: "National Savings Bank" },
  { key: "18", value: "Nations Trust Bank" },
  { key: "19", value: "Pan Asia Bank" },
  { key: "20", value: "People's Bank" },
  { key: "21", value: "Sampath Bank" },
  { key: "22", value: "Sanasa Development Bank" },
  { key: "23", value: "Sarvodaya Development Finance" },
  { key: "24", value: "Senkadagala Finance" },
  { key: "25", value: "Seylan Bank" },
  { key: "26", value: "Singer Finance" },
  { key: "27", value: "Standard Chartered Bank" },
  { key: "28", value: "Union Bank of Colombo" },
  { key: "29", value: "Vallibel Finance" },
];

export const gameType = [
  { key: "1", value: "1X BET" },
  { key: "2", value: "MEL BET" },
  { key: "3", value: "OTHER" },
];

export const dateFormat = "yyyy-MM-dd HH:mm:ss";
export const MESSAGE_ERROR = "Transaction is not successfully completed";
export const MESSAGE_SUCCESS = "Transaction is successfully completed";
export const HOTLINE_NO = process.env.REACT_APP_HOTLINE;


export const WHATSAPP_URL = "https://wa.me/";
export const CommonWhatsAppNumber = process.env.REACT_APP_COMMON_WHATSAPP_NO;
// window.open(`${WHATSAPP_URL}`, '_blank');


export const banners = [
  { image: "/banners/1.jpg" },
  { image: "/banners/2.jpg" },
  { image: "/banners/3.jpg" },
  { image: "/banners/4.jpg" },
];
