import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import imgRefresh from "../asset/img/refresh.png";

const NotifyItem = ({
  indexid,
  notification,
  isnew,
  color,
  font,
  badgeClass,
  objitem,
  isReadStyle,
  displayType
}) => {
  //////////////USER ///////////////////
  const { user } = useContext(UserContext);
  //////////////USER ///////////////////


  const Class = `list-group-item d-flex justify-content-between align-items-center mb-1px ${color} ${font} ${isReadStyle}`;

  // ナヴィゲーションを生成
  const navigate = useNavigate();

  const handleNotificaionClick = async (e) => {
    if (objitem){
      if ((displayType?? '') === "U") {  // user
        navigate(`/user`, {
          state: { id: objitem._id, currentUser: user },
        });
      }
      else
      {
        // redirect to the deposit form with selected deposit id/ customer id / user
        if (objitem.withdrawStatus || objitem.type[0] === "W") {
          navigate(`/withdraw`, {
            state: { id: objitem._id, userId: objitem.userId, currentUser: user },
          });
        } else {
          navigate(`/deposit`, {
            state: { id: objitem._id, userId: objitem.userId, currentUser: user },
          });
        }
      }
    }
    
    
  };

  return (
    <li id={indexid} className={Class} onClick={handleNotificaionClick}>
      {notification}
      <span className={`badge rounded-pill with-100px ${badgeClass}`}>{isnew}</span>
      
    </li>
  );
};

NotifyItem.propTypes = {
  notification: PropTypes.string.isRequired,
  isnew: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  font: PropTypes.string.isRequired,
};

export default NotifyItem;
