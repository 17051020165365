import React, { useState, useEffect, useContext } from "react";
import GridList from "./GridList";
import {
  apiUrl,
  depositTypesWithId,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
} from "../common/const";
import imgSearch from "../asset/img/search-24.png";
import { UserContext } from "../context/UserContext";
import { notifySuccess, notifyError, notifyInfo } from "./Toaster";
import { useNavigate } from "react-router-dom";
import NotifyItem from "./NotifyItem";

const DepositList = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTxt, setSearchTxt] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [drpStatus, setDrpStatus] = useState("");

  // ナヴィゲーションを生成
  const navigate = useNavigate();

  //////////////USER ///////////////////
  const { user } = useContext(UserContext);
  //////////////USER ///////////////////

  useEffect(() => {
    const formData = {
      searchtxt: searchTxt,
      fromdate: fromDate,
      todate: toDate,
      currentUser: user,
    };

    // Call the fetchData function
    if (!user) {
      navigate("/");
    } else {
      fetchData(formData);
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const fetchData = async (reqData) => {
    try {
      const response = await fetch(`${apiUrl}/deposit/all`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqData),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result);
      let { message } = result;
      if (message) {
      }
    } catch (error) {
      const { message } = error;
      notifyError(message ?? MESSAGE_ERROR);
    } finally {
      setLoading(false);
    }
  };

  const onClickHandlerImgSearch = (e) => {
    const formData = {
      searchtxt: searchTxt,
      fromdate: fromDate,
      todate: toDate,
      currentUser: user,
      depositStatus: drpStatus,
      limit: 10,
    };

    fetchData(formData);
  };

  const onChangeHanderFromDate = (e) => {
    setFromDate(e.target.value);
  };

  const handleStatusChange = (e) => {
    setDrpStatus(e.target.value);
  };

  const onChangeHanderToDate = (e) => {
    setToDate(e.target.value);
  };

  const onChangeHanderSearch = (e) => {
    setSearchTxt(e.target.value);
  };

  if (loading) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-success" role="alert">
              Data is loading....
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-danger" role="alert">
              Unexpected error....
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container pb-1 ">
       <div className="col">
       <ul className="list-group"> 
      <NotifyItem
          indexid="first"
          notification="Deposit List"
          isnew=""
          color="bg-header"
          font="font-b-16"
        ></NotifyItem>
        </ul></div>
      <div className="bg-header round-edge p-1">        
        <div className="row">
          <div className="col-10 col-xs-10 col-sm-10 col-md-10 pe-0">
            <div className="row m-0">
              <div className="col-2 col-xs-2 col-sm-2 col-md-2">
                <span
                  className="font-b-14 color-black text-left"
                  style={{ verticalAlign: "super" }}
                >
                  Status
                </span>
              </div>
              <div className="col-8 col-xs-8 col-sm-8 col-md-8 p-0 pb-1">
                <input
                  id="txtSearch"
                  type="search"
                  className="form-control width-full"
                  value={searchTxt}
                  onChange={onChangeHanderSearch}
                  placeholder="Searching criteria "
                  style={{ display: "none" }}
                />

                <select
                  id="drpdepositType"
                  className="form-select font-r-16 width-full py-1"
                  value={drpStatus}
                  onChange={handleStatusChange}
                >
                  {depositTypesWithId.map((item, index) => (
                    <option value={item.key}>{item.value}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-2 col-xs-2 col-sm-2 col-md-2">
                <span
                  className="font-b-14 color-black text-left"
                  style={{ verticalAlign: "super" }}
                >
                  From
                </span>
              </div>
              <div className="col-4 col-xs-4 col-sm-4 col-md-4 p-0">
                <input
                  id="txtFromDate"
                  type="date"
                  className="form-control width-full font-b-14 "
                  value={fromDate}
                  onChange={onChangeHanderFromDate}
                />
              </div>
              <div className="col-2 col-xs-2 col-sm-2 col-md-2">
                <span
                  className="font-b-14 color-black text-left"
                  style={{ verticalAlign: "super" }}
                >
                  To
                </span>
              </div>
              <div className="col-4 col-xs-4 col-sm-4 col-md-4 p-0">
                <input
                  id="txttoDate"
                  type="date"
                  className="form-control width-full font-b-14 "
                  value={toDate}
                  onChange={onChangeHanderToDate}
                />
              </div>
            </div>
          </div>
          <div className="col-2 col-xs-2 col-sm-2 col-md-2">
            <img
              id="btnSearch"
              src={imgSearch}
              className="round-edge white-border p-2"
              onClick={onClickHandlerImgSearch}
              alt=""
            />
          </div>
        </div>
        <div className="row"></div>
      </div>
      <div className="row">
        <div className="col-12 col-xs-12 col-sm-12 col-md-12">
          {data.map((item, index) => (
            <GridList
              item={item}
              font="font-b-16"
              index={index}
              id={index}
            ></GridList>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DepositList;
