import React, { useState, useEffect, useContext } from "react";
import CurrencyInput from "react-currency-input-field";
import SubHeader from "../SubHeader";
import {
  apiUrl,
  withdrawStatus,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  gameType,
  WHATSAPP_URL,
  CommonWhatsAppNumber,
} from "../../common/const";
import { useLocation } from "react-router-dom";
import { notifySuccess, notifyError, notifyInfo } from "../Toaster";
import imgChat from "../../asset/img/chat-32.png";
import imgCopy from '../../asset/img/copy.png';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import SubmitButton from "../SubmitButton";
import RedepositItem from "./RedepositItem";

const Withdraw = () => {
  const location = useLocation();
  const { id, userId } = location.state || {};

  // ナヴィゲーションを生成
  const navigate = useNavigate();

  //////////////USER ///////////////////
  const { user } = useContext(UserContext);
  //////////////USER ///////////////////

  // ゲームID
  const [gameId, setGameId] = useState("");
  const [redepositGameId, setRedepositGameId] = useState("");

  // 金額のuseStateを設定
  const [amount, setAmount] = useState();
  // 銀行のuseStateを設定
  const [bank, setBank] = useState("");
  const [banks, setBanks] = useState(null);
  // 特別記事のuseStateを設定
  const [specialRemarks, setSpecialRemarks] = useState("");
  // エラーuseStateを設定
  const [errors, setErrors] = useState({});
  // アップロードしたファイルを開くのuseStateを設定
  const [preview, setPreview] = useState(null);
  // 選択した銀行情報
  const [selectedBank, setSelectedBank] = useState(null);
  // 取得した情報
  const [data, setData] = useState({});
  // 非活性
  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  // ゲームタイプ
  const [selectedGameType, setSelectedGameType] = useState("");
  const [selectedRedepositGameType, setSelectedRedepositGameType] =
    useState("");
  // withdrawコード
  const [withdrawCode, setWithdrawCode] = useState("");
  // 添付ファイル
  const [fileUploader, setFileUploader] = useState(null);
  //
  const [details, setDetails] = useState([]);
  // redeposit details
  const [redepositDetails, setRedepositDetails] = useState([]);
  // Verify amount
  const [verifyBalanceAmount, setVerifyBalanceAmount] = useState("");
  // confirm withdraw amount
  const [confirmWithdrawAmount, setConfirmWithdrawAmount] = useState("");
  // confirm re-deposit amount
  const [confirmRedepositAmount, setConfirmRedepositAmount] = useState(
    data.confirmRedepositAmount ?? "0"
  );

  // complete and reject
  const [rejectReason, setRejectReason] = useState("");
  const [completeReceiptUploader, setCompleteReceiptUploader] = useState(null);
  const [completeReceiptPreview, setCompleteReceiptPreview] = useState(null);
  const [completeBalanceAmount, setCompleteBalanceAmount] = useState("");
  const [newBalanceAmount, setNewBalanceAmount] = useState("");
  const [reDepositReceiptUploader, setReDepositReceiptUploader] =
    useState(null);
  const [reDepositReceiptPreview, setReDepositReceiptPreview] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [verifyUploader, setVerifyUploader] = useState(null);
  const [verifyPreview, setVerifyPreview] = useState(null);
  const [gameIdList, setGameIdList] = useState("");

  // エラーメッセージを設定
  var errorAmount = "Please enter the Withdraw Amount";
  var errorAttachedFile = "Please attached the file";
  var errorGameId = "Please enter the Game Id";
  var errorRedepositGameId = "Please enter the Re-deposit Game Id";
  var errorBank = "Please select the Bank";
  var errorGameType = "Please select the Game Type";
  var errorRedepositGameType = "Please select the Re-deposit Game Type";
  var errorWithdrawCode = "Please enter the Withdraw Code";
  var errorDetail = "Please enter the details";
  var errorFileUploader = "Please select the attachment";
  var errorVerifyBalanceAmount = "Please enter the Balance Amount";
  var errorConfirmWithdrawAmount =
    "Please enter the Confirmation Withdraw Amount";
  var errorConfirmRedepositAmount = "Please enter the Re-deposit Amount";
  var errorCompleteBalanceAmount = "Please enter the Balance Amount";
  var errorRejectReason = "Please enter the Reject Reason";
  var errorCompleteReceiptUploader = "Please add the Receipt attachment";
  var errorNewBalanceAmount = "Please enter the new Balance Amount to Withdraw";
  var errorReDepositReceiptUploader = "Please upload the re-deposit attachment";
  var errorVerifyUploader = "Please add the attachment";
  var errorBalanceAmount = "Please enter the balance Amount";
  var errorVerifyAmountCheck = "Verify amount is greater than withdraw amount";
  var errorGreaterThanCheck = `amount is greater than`;

  // バリデーションを設定
  const validate = () => {
    const newErrors = {};
    if (!amount) newErrors.errAmount = errorAmount;
    if (!bank) newErrors.errBank = errorBank;
    if (details.length === 0) newErrors.errDetails = errorDetail;
    return newErrors;
  };

  // バリデーションを設定
  const validateDetail = () => {
    const newErrors = {};
    if (!gameId) newErrors.errGameId = errorGameId;
    if (!withdrawCode) newErrors.errWithdrawCode = errorWithdrawCode;
    if (!selectedGameType) newErrors.errGameType = errorGameType;
    if (!fileUploader) newErrors.errFileUploader = errorFileUploader;
    return newErrors;
  };

  const validateConfirm = () => {
    const newErrors = {};
    if (!confirmRedepositAmount)
      newErrors.errConfirmRedepositAmount = errorConfirmRedepositAmount;
    if (!confirmWithdrawAmount)
      newErrors.errConfirmWithdrawAmount = errorConfirmWithdrawAmount;
    if (!redepositGameId) newErrors.errRedepositGameId = errorRedepositGameId;
    if (!selectedRedepositGameType)
      newErrors.errRedepositGameType = errorRedepositGameType;
    if (confirmRedepositAmount > data?.balanceAmount) {
      newErrors.errRedepositValueCheck = `Redeposit ${errorGreaterThanCheck} verify amount`;
    }
    if (confirmWithdrawAmount > data?.balanceAmount) {
      newErrors.errWithdrawValueCheck = `Withdraw ${errorGreaterThanCheck} verify amount`;
    }
    if (
      Number(confirmRedepositAmount) + Number(confirmWithdrawAmount) !=
      data?.balanceAmount
    ) {
      newErrors.errWithdrawNRepaymentValueCheck = `Total is must tall with balance amount`;
    }
    return newErrors;
  };

  const completeValidate = () => {
    const newErrors = {};
    if (!completeBalanceAmount)
      newErrors.errCompleteBalanceAmount = errorCompleteBalanceAmount;
    if (!completeReceiptUploader)
      newErrors.errCompleteReceiptUploader = errorCompleteReceiptUploader;
    return newErrors;
  };

  const reDepositValidate = () => {
    const newErrors = {};
    if (!newBalanceAmount)
      newErrors.errNewBalanceAmount = errorNewBalanceAmount;
    if (!reDepositReceiptUploader)
      newErrors.errReDepositReceiptUploader = errorReDepositReceiptUploader;
    if (data?.confirmRedepositAmount < newBalanceAmount)
      newErrors.errRedepositedAmountCheck = `Re-deposit ${errorGreaterThanCheck} requested amount`;
    return newErrors;
  };

  const VerifyValidate = () => {
    const newErrors = {};
    if (isChecked) {
      if (!rejectReason) newErrors.errRejectReason = errorRejectReason;
    } else {
      if (!verifyBalanceAmount)
        newErrors.errVerifyBalanceAmount = errorBalanceAmount;

      if (data?.amount < verifyBalanceAmount)
        newErrors.errVerifyAmountCheck = errorVerifyAmountCheck;
      //if (!verifyUploader) newErrors.errVerifyUploader = errorVerifyUploader;
      const allHaveAttachment = details.every((item) =>
        item.hasOwnProperty("attachment")
      );
      if (!allHaveAttachment) {
        newErrors.errVerifyUploader = errorVerifyUploader;
      }
    }

    return newErrors;
  };

  useEffect(() => {
    const fetchData = async () => {
      // prevent multiple clicks
      if (posting) return;

      // set posting to True
      setPosting(true);

      try {
        setLoading(true);

        const response = await fetch(`${apiUrl}/withdraw/this`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: id,
            userId: userId,
            currentUser: { _id: user._id, userLevel: user.userLevel },
          }),
        });
        if (response.ok) {
          const reqData = await response.json();
          setData(reqData);
          if (reqData) {
            // select bank
            const tbank = JSON.parse(reqData.bank);
            setBank(user?.accounts?.findIndex((x) => x._id === tbank._id));
            setIsChecked(reqData.withdrawStatus === 6);
            // set confirm withdraw amount
            setConfirmWithdrawAmount(
              reqData.confirmWithdrawAmount ?? reqData.balanceAmount
            );
            // set selection options status, for complete or reject
            setSelectedBank(JSON.parse(reqData.bank));
            setDisabled(true);
            if (reqData.withdrawStatus === 2 || reqData.withdrawStatus === 3) {
              // setReceiptDisabled(true);
            }
          }
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
        setLoading(false);
        setPosting(false);
      }
    };

    const fetchDetails = async () => {
      try {
        const response = await fetch(`${apiUrl}/withdraw/thisdetails`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: id,
            userId: userId,
            currentUser: { _id: user._id, userLevel: user.userLevel },
          }),
        });
        if (response.ok) {
          const reqData = await response.json();
          setDetails(reqData);
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
      }
    };

    const fetchRedepositDetails = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/withdraw/thisredepositdetails`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              id: id,
              userId: userId,
              currentUser: { _id: user._id, userLevel: user.userLevel },
            }),
          }
        );
        if (response.ok) {
          const reqData = await response.json();
          setRedepositDetails(reqData);
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
      }
    };

    // Call the fetchData function
    if (!user) {
      navigate("/");
    } else {
      if (id) {
        fetchData();
        fetchDetails();
        fetchRedepositDetails();
      }
      // 銀行情報を抽出
      setBanks(user?.accounts ?? []);
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  // 特別記事のハンドラーを設定
  const handleSpecialRemarksChange = (e) => setSpecialRemarks(e.target.value);

  // 金額のハンドラーを設定
  const handleAmountChange = (value) => {
    // 金額設定
    setAmount(value);
    // エラー変数を生成
    var error = "";
    if (!value) {
      // エラーを付与
      error = errorAmount;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, errAmount: error }));
  };

  // デポジットタイプのハンドラーを設定
  const handleBankChange = (e) => {
    // 選択した値を設定
    setBank(e.target.value);
    setSelectedBank(banks[e.target.value]);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorBank;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, errBank: error }));
  };

  // ファイルアップロードのハンドラーを設定
  const handleFileUploaderChange = (event) => {
    // 選択したファイルを抽出
    const file = event.target.files[0];
    // エラー変数を生成
    var error = "";
    // ファイルを設定
    setFileUploader(file);
    if (file) {
      // ファイルのオブジェクトを生成
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    } else {
      // エラーを付与
      error = errorAttachedFile;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, errFileUploader: error }));
  };

  // サブミット―を設定
  const handleSubmit = async (e) => {
    // Prevent default form submission
    e.preventDefault();
    // バリデーションを実行
    const validationErrors = validate();
    //　エラーアレイを判断
    if (Object.keys(validationErrors).length > 0) {
      // エラーを設定
      setErrors(validationErrors);
    } else {
      // エラーをクリアする
      setErrors({});

      // prevent multiple clicks
      if (posting) return;

      // set posting to True
      setPosting(true);

      // create array without file
      const detailsWithoutFile = details.map(
        ({ gameId, withdrawCode, selectedGameType, gameTypeName }) => ({
          gameId,
          withdrawCode,
          selectedGameType,
          gameTypeName,
        })
      );

      // フォームデータを作成
      const formData = new FormData();
      formData.append("userId", user._id);
      formData.append("amount", amount);
      formData.append("bank", JSON.stringify(selectedBank));
      formData.append("specialRemark", specialRemarks);
      formData.append("withdrawStatus", 1);
      formData.append("currentUser", JSON.stringify(user));
      formData.append("details", JSON.stringify(detailsWithoutFile));
      formData.append("gameId", gameIdList);

      details.forEach((item, index) => {
        if (item.fileUploader) {
          formData.append(`files[${index}]`, item.fileUploader);
        }
      });
      try {
        const response = await fetch(`${apiUrl}/withdraw/save`, {
          method: "POST",
          headers: { enctype: "multipart/form-data" },
          body: formData,
        });
        if (response.ok) {
          const result = await response.json();
          // Reset form after successful submission
          clearElement();
          notifySuccess(MESSAGE_SUCCESS);
          navigate("/home");
        } else {
          notifyError(response.statusText);
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
        setPosting(false);
      }
    }
  };

  // エレメントをクリアする
  const clearElement = () => {
    setGameId("");
    setRedepositGameId("");
    setSelectedGameType("");
    setSelectedRedepositGameType("");
    setErrors("");
    setFileUploader(null);
    setAmount("");
    setSpecialRemarks("");
    setPreview(null);
    setData({});
    setBank("");
    setSelectedBank(null);
    setDetails([]);
  };

  const handleWithdrawCodeChange = (e) => {
    // 選択した値を設定
    setWithdrawCode(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorWithdrawCode;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, errWithdrawCode: error }));
  };

  const handleGameIdChange = (e) => {
    // 選択した値を設定
    setGameId(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorGameId;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, errGameId: error }));
  };

  const handleGameTypeChange = (e) => {
    // 選択した値を設定
    setSelectedGameType(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorGameType;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, errGameType: error }));
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert(`${text} is copied`);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleAddDetails = () => {
    // バリデーションを実行
    const validationErrors = validateDetail();
    //　エラーアレイを判断
    if (Object.keys(validationErrors).length > 0) {
      // エラーを設定
      setErrors(validationErrors);
    } else {
      // Create a new object
      const newDetail = {
        gameId,
        withdrawCode,
        selectedGameType,
        gameTypeName: gameType.find((x) => x.key === selectedGameType).value,
        fileUploader,
      };

      // Add new account to the list
      setDetails([...details, newDetail]);

      setGameIdList(gameIdList + "/" + gameId);

      // Clear input fields
      setGameId("");
      setWithdrawCode("");
      setSelectedGameType("");
      setFileUploader(null);
      setPreview(null);
    }
  };

  // Handle Delete button click
  const handleDeleteDetail = (index) => {
    // Filter out the account to be deleted by its index
    const updatedDetails = details.filter((_, i) => i !== index);
    setDetails(updatedDetails);
  };

  const handleDownload = (attachmentId, withdrawCode) => {
    // prevent multiple clicks
    if (posting) return;
    try {
      // set posting to True
      setPosting(true);

      fetch(`${apiUrl}/withdraw/attachment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: attachmentId,
          userId: userId,
          currentUser: { _id: user._id, userLevel: user.userLevel },
        }),
      })
        .then((response) => {
          if (!response.ok) {
            notifyError(MESSAGE_ERROR);
          }
          return response.blob(); // Expect a Blob as the response
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `Withdraw_GameId_${withdrawCode}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          notifySuccess("Download is successfull");
        })
        .catch((error) => {
          const { message } = error;
          notifyError(message ?? MESSAGE_ERROR);
        })
        .finally(() => {
          // set posting to False
          setPosting(false);
        });
    } catch (error) {
      const { message } = error;
      notifyError(message ?? MESSAGE_ERROR);
    }
  };

  const handleBalanceAmountChange = (value) => {
    // 選択した値を設定
    setVerifyBalanceAmount(value);
    // エラー変数を生成
    var error = "";
    var errorCheck = "";
    if (!value) {
      // エラーを付与
      error = errorVerifyBalanceAmount;
    } else {
      if (data?.amount < value) errorCheck = errorVerifyAmountCheck;
    }

    // エラーアレイに追加
    setErrors((prevErrors) => ({
      ...prevErrors,
      errVerifyBalanceAmount: error,
      errVerifyAmountCheck: errorCheck,
    }));
  };

  // Verify
  const handleVerify = async (e) => {
    // バリデーションを実行
    const validationErrors = VerifyValidate();
    //　エラーアレイを判断
    if (Object.keys(validationErrors).length > 0) {
      // エラーを設定
      setErrors(validationErrors);
    } else {
      // エラーをクリアする
      setErrors({});

      // prevent multiple clicks
      if (posting) return;

      // set posting to True
      setPosting(true);

      // create array without file
      const detailsWithoutFile = details.map(
        ({ _id, gameId, withdrawCode, selectedGameType, gameTypeName }) => ({
          _id,
          gameId,
          withdrawCode,
          selectedGameType,
          gameTypeName,
        })
      );

      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("id", id);
      formData.append("withdrawStatus", isChecked ? 6 : 2);
      formData.append(
        "reference",
        isChecked ? rejectReason : verifyBalanceAmount
      );
      formData.append(
        "currentUser",
        JSON.stringify({ _id: user._id, userLevel: user.userLevel })
      );
      if (isChecked) {
        formData.append("attachment", verifyUploader);
      } else {
        formData.append("details", JSON.stringify(detailsWithoutFile));
        details.forEach((item, index) => {
          if (item.attachment) {
            formData.append(`files[${index}]`, item.attachment);
          }
        });
      }
      try {
        const response = await fetch(`${apiUrl}/withdraw/verify`, {
          method: "POST",
          headers: { enctype: "multipart/form-data" },
          body: formData,
        });
        if (response.ok) {
          const reqData = await response.json();
          setData(reqData);
          notifySuccess(MESSAGE_SUCCESS);
          navigate("/WithdrawList");
          if (reqData) {
            setDisabled(true);
          }
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
        setPosting(false);
      }
    }
  };

  const handleCompleteBalanceAmount = (value) => {
    // 金額設定
    setCompleteBalanceAmount(value);
    // エラー変数を生成
    var error = "";
    if (!value) {
      // エラーを付与
      error = errorCompleteBalanceAmount;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({
      ...prevErrors,
      errCompleteBalanceAmount: error,
    }));
  };

  const handleRejectReasonChange = (event) => {
    // 金額設定
    setRejectReason(event.target.value);

    // エラー変数を生成
    var error = "";
    if (!event.target.value) {
      // エラーを付与s
      error = errorRejectReason;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, errRejectReason: error }));
  };

  const handleCompleteReceiptUploaderChange = (event) => {
    // 選択したファイルを抽出
    const file = event.target.files[0];
    // エラー変数を生成
    var error = "";
    // ファイルを設定
    setCompleteReceiptUploader(file);
    if (file) {
      // ファイルのオブジェクトを生成
      const objectUrl = URL.createObjectURL(file);
      setCompleteReceiptPreview(objectUrl);
    } else {
      // エラーを付与
      error = errorCompleteReceiptUploader;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({
      ...prevErrors,
      errCompleteReceiptUploader: error,
    }));
  };

  const handleVerifyUploaderChange = (id, event) => {
    // 選択したファイルを抽出
    const file = event.target.files[0];
    // エラー変数を生成
    var error = "";
    // ファイルを設定
    if (file) {
      if (id === "R") {
        // ファイルを設定
        setVerifyUploader(file);
        if (file) {
          // ファイルのオブジェクトを生成
          const objectUrl = URL.createObjectURL(file);
          setVerifyPreview(objectUrl);
        }
      } else {
        // ファイルのオブジェクトを生成
        setDetails((prevDetails) =>
          prevDetails.map((dtls) =>
            dtls._id === id ? { ...dtls, attachment: file } : dtls
          )
        );
      }
    } else {
      // エラーを付与
      error = errorVerifyUploader;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({
      ...prevErrors,
      errVerifyUploader: error,
    }));
  };

  const handleReceiptDownload = () => {
    // prevent multiple clicks
    if (posting) return;

    // set posting to True
    setPosting(true);

    fetch(`${apiUrl}/withdraw/receiptattachment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        userId: userId,
        currentUser: { _id: user._id, userLevel: user.userLevel },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          notifyError(MESSAGE_ERROR);
        }
        return response.blob(); // Expect a Blob as the response
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Withdraw_Receipt_GameId_${data._id}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        notifySuccess("Download is successfull");
      })
      .catch((error) => {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      })
      .finally(() => {
        // set posting to False
        setPosting(false);
      });
  };

  const handleVerifyDownload = (attach_id) => {
    // prevent multiple clicks
    if (posting) return;

    // set posting to True
    setPosting(true);

    fetch(`${apiUrl}/withdraw/verifyattachment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        userId: userId,
        currentUser: { _id: user._id, userLevel: user.userLevel },
        attach_id: attach_id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          notifyError(MESSAGE_ERROR);
        }
        return response.blob(); // Expect a Blob as the response
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Withdraw_Verify_GameId_${data._id}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        notifySuccess("Download is successfull");
      })
      .catch((error) => {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      })
      .finally(() => {
        // set posting to False
        setPosting(false);
      });
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const openWhatsApp = (telNo) => {
    console.log(
      telNo,
      (user?.userLevel ?? 2) === 2,
      user?.userLevel ?? "2",
      CommonWhatsAppNumber
    );
    if ((user?.userLevel ?? 2) === 2) {
      telNo = CommonWhatsAppNumber;
    }

    if (telNo !== "")
      window.open(`${WHATSAPP_URL}+94${telNo.trim()}`, "_blank");
    else
      notifyInfo(
        "Invalid Telephone Number, Please check the customer telephone Number"
      );
  };

  const handleComplete = async (e) => {
    // バリデーションを実行
    const validationErrors = completeValidate();
    //　エラーアレイを判断
    if (Object.keys(validationErrors).length > 0) {
      // エラーを設定
      setErrors(validationErrors);
    } else {
      // エラーをクリアする
      setErrors({});

      // prevent multiple clicks
      if (posting) return;

      // set posting to True
      setPosting(true);

      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("id", id);
      formData.append("reference", completeBalanceAmount);
      formData.append(
        "currentUser",
        JSON.stringify({ _id: user._id, userLevel: user.userLevel })
      );
      formData.append("attachment", completeReceiptUploader);
      try {
        const response = await fetch(`${apiUrl}/withdraw/complete`, {
          method: "POST",
          headers: { enctype: "multipart/form-data" },
          body: formData,
        });
        if (response.ok) {
          const reqData = await response.json();
          setData(reqData);
          notifySuccess(MESSAGE_SUCCESS);
          //navigate("/WithdrawList");
          navigate(-1);
          if (reqData) {
            setDisabled(true);
          }
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
        setPosting(false);
      }
    }
  };

  // プレビューできるファイルタイプ
  const fileTypes = [".png", ".jpg", ".ico", ".bmp", "jpeg"];

  if (loading) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-success" role="alert">
              Data is loading....
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-info" role="alert">
              Invalid user, Please log again
            </div>
          </div>
        </div>
      </div>
    );
  }

  // 戻り値
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="container body round-edge button-border">
          <div className="row text-left ">
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <SubHeader title="Money Withdraw"></SubHeader>
            </div>
            <div className="row m-0 p-0">
              <div className="col-6 col-xs-6 col-sm-6 col-md-6 bg-customerId">
                <span className="font-b-16 color-black">Telephone No : </span>
                <span className="font-b-16 color-black">
                  {data?.telephoneNo ?? user?.telephoneNo}
                </span>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-6 bg-customerId">
                <span className="font-b-16 color-black">Status : </span>
                <span className="font-b-16 color-black">
                  {withdrawStatus[data.withdrawStatus]}
                </span>
              </div>
            </div>

            {/* amount */}
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <span className="font-b-14 color-black">Amount</span>
            </div>
            <div className="row m-0 p-0">
              <div className="col-6 col-xs-6 col-sm-6 col-md-6">
                <CurrencyInput
                  placeholder=""
                  decimalsLimit={2}
                  onValueChange={handleAmountChange}
                  prefix="Rs  "
                  className="font-r-14 width-full form-control"
                  value={data.amount ?? amount}
                  disabled={disabled}
                />
              </div>

              <div className="col-6 col-xs-6 col-sm-6 col-md-6">
                <button
                  type="button"
                  className="btn btn-outline-warning p-1 font-r-14 "
                  onClick={() =>
                    openWhatsApp(data?.telephoneNo ?? user?.telephoneNo ?? "")
                  }
                >
                  <img
                    src={imgChat}
                    alt=""
                    style={{ verticalAlign: "1px", width: "24px" }}
                  />
                  <span style={{ verticalAlign: "super", paddingLeft: "10px" }}>
                    Chat
                  </span>
                </button>
              </div>
            </div>
            <div className="font-r-12 color-error">
              {errors.errAmount && <span>{errors.errAmount}</span>}
            </div>
            {/* Banks */}
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
              <span className="font-b-14 color-black">Bank Detail</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <select
                className="form-select font-r-14 width-half py-1"
                value={bank}
                onChange={handleBankChange}
                disabled={disabled}
              >
                <option value="">Select Bank</option>
                {banks &&
                  banks.map((item, index) => (
                    <option value={index}>{item.bankName}</option>
                  ))}
              </select>
              <div className="font-r-12 color-error">
                {errors.errBank && <span>{errors.errBank}</span>}
              </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              {selectedBank && (
                <div
                  className="bg-detail detail"
                  style={{ padding: "0px 0px 10px 30px" }}
                >
                  <p className="font-b-14 color-black m-0">Bank Details</p>

                  <p className="font-r-14 color-black m-0">
                    Account Name:{selectedBank.accountName}
                  </p>

                  <p className="font-r-14 color-black m-0">
                    Account No :{selectedBank.accountNo}
                  </p>

                  <p className="font-r-14 color-black m-0">
                    Bank Name :{selectedBank.bankName}
                  </p>

                  <p className="font-r-14 color-black m-0">
                    Branch :{selectedBank.branch}
                  </p>
                  <button
                    type="button"
                    className="btn btn-warning p-1 font-r-14 "
                    onClick={() => copyToClipboard(selectedBank.accountName)}
                  >
                    <img
                      src={imgCopy}
                      alt=""
                      style={{ verticalAlign: "1px", width: "24px" }}
                    />
                    <span
                      style={{ verticalAlign: "super", paddingLeft: "10px" }}
                    >
                      Copy Account Name
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning ms-1 p-1 font-r-14 "
                    onClick={() => copyToClipboard(selectedBank.accountNo)}
                  >
                    <img
                      src={imgCopy}
                      alt=""
                      style={{ verticalAlign: "1px", width: "24px" }}
                    />
                    <span
                      style={{ verticalAlign: "super", paddingLeft: "10px" }}
                    >
                      Copy Account No
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning ms-1 p-1 font-r-14 "
                    onClick={() => copyToClipboard(selectedBank.bankName)}
                  >
                    <img
                      src={imgCopy}
                      alt=""
                      style={{ verticalAlign: "1px", width: "24px" }}
                    />
                    <span
                      style={{ verticalAlign: "super", paddingLeft: "10px" }}
                    >
                      Bank Name
                    </span>
                  </button>
                </div>
              )}
            </div>
            {!disabled && (
              <div
                className="container body round-edge button-border mt-2 pb-1"
                style={{ width: "98%" }}
              >
                {/* Game Id */}
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
                  <span className="font-b-14 color-black">Game Id</span>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 pe-0">
                  <input
                    type="text"
                    className="font-r-14 width-half form-control form-control-sm"
                    value={data.gameId == undefined ? gameId : data.gameId}
                    onChange={handleGameIdChange}
                    disabled={disabled}
                  />
                </div>
                <div className="font-r-12 color-error">
                  {errors.errGameId && <span>{errors.errGameId}</span>}
                </div>
                {/* withdraws details */}
                <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                  <span className="font-b-14 color-black">Withdraw code</span>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
                  <input
                    type="text"
                    className="font-r-14 width-half form-control"
                    value={withdrawCode}
                    onChange={handleWithdrawCodeChange}
                    disabled={disabled}
                  />
                </div>
                <div className="font-r-12 color-error">
                  {errors.errWithdrawCode && (
                    <span>{errors.errWithdrawCode}</span>
                  )}
                </div>
                {/* game Type */}
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
                  <span className="font-b-14 color-black">Game Type</span>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                  <select
                    className="form-select font-r-14 width-half py-1"
                    value={selectedGameType}
                    onChange={handleGameTypeChange}
                    disabled={disabled}
                  >
                    <option value="">Select an option</option>
                    {gameType.map((item, index) => (
                      <option value={item.key}>{item.value}</option>
                    ))}
                  </select>
                  <div className="font-r-12 color-error">
                    {errors.errGameType && <span>{errors.errGameType}</span>}
                  </div>
                </div>

                {/* attachment  */}
                <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                  <span className="font-b-14 color-black">Attachment</span>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input form-control"
                        onChange={handleFileUploaderChange}
                        disabled={disabled}
                        ref={fileUploader}
                      />
                      <div className="font-r-12 color-error">
                        {errors.errFileUploader && (
                          <span>{errors.errFileUploader}</span>
                        )}
                      </div>
                      {preview &&
                        fileTypes.findIndex(
                          (item) => item === fileUploader?.name.substr(-4)
                        ) !== -1 && <img src={preview} alt={""} width="100" />}
                    </div>
                  </div>
                </div>
                {/* adding details */}
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 pe-0">
                  <SubmitButton
                    caption="Add Details"
                    buttonType="button"
                    color="btn-outline-primary width-half"
                    onClick={handleAddDetails}
                  ></SubmitButton>
                </div>
                <div className="font-r-12 color-error">
                  {errors.errDetails && <span>{errors.errDetails}</span>}
                </div>
              </div>
            )}
            {/* show added details */}
            {details.length > 0 && (
              <div className="col-12 col-xs-12 col-sm-12 col-md-12 mt-3">
                <div className="table-responsive">
                  <table className="table table-sm   font-r-14 button-border ">
                    <thead className="table-info">
                      <tr>
                        <th scope="col" className=" bg-wheat">
                          Game Id
                        </th>
                        <th scope="col" className=" bg-wheat">
                          Withdraw Code
                        </th>
                        <th scope="col" className=" bg-wheat">
                          Game Type
                        </th>
                        <th scope="col" className=" bg-wheat">
                          Attachment
                        </th>
                        <th scope="col" className=" bg-wheat"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {details.map((item, index) => (
                        <tr scope="row" key={index}>
                          <td>
                            <button
                              type="button"
                              className="table-button"
                              onClick={() => copyToClipboard(item.gameId)}
                            >
                              {item.gameId}
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="table-button"
                              onClick={() => copyToClipboard(item.withdrawCode)}
                            >
                              {item.withdrawCode}
                            </button>
                          </td>
                          <td>{item.gameTypeName}</td>
                          <td>
                            {disabled === false ? (
                              item?.fileUploader?.name
                            ) : (
                              <SubmitButton
                                caption="Download"
                                buttonType="button"
                                color="btn-outline-success"
                                onClick={() =>
                                  handleDownload(item._id, item.withdrawCode)
                                }
                                disabled={posting}
                              ></SubmitButton>
                            )}
                          </td>
                          <td>
                            {disabled === false ? (
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDeleteDetail(index)}
                              >
                                Delete
                              </button>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {/* special note */}
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <span className="font-b-14 color-black">Special Notes</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <textarea
                value={data.specialRemark ?? specialRemarks}
                onChange={handleSpecialRemarksChange}
                placeholder=""
                rows={4}
                className="width-full form-control"
                disabled={disabled}
              />
            </div>
            {/* save button */}
            {!disabled && (
              <div className="row">
                <div className="col-4 col-xs-4 col-sm-4 col-md-4 pb-1">
                  <SubmitButton
                    caption="Save"
                    buttonType="submit"
                    color="btn-outline-warning"
                    disabled={posting}
                  ></SubmitButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      {/* varification */}
      {data.withdrawStatus >= 1 && (
        <div className="container body round-edge button-border mt-1">
          {/* reject check box */}
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 form-check form-switch">
            <label className="font-b-14 color-black">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={data.withdrawStatus > 1 || user.userLevel === 2}
              />
              Reject Withdraw
            </label>
          </div>
          {isChecked ? (
            <>
              <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                <span className="font-b-16 color-black">Reject Reason</span>
              </div>
              <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                <textarea
                  onChange={handleRejectReasonChange}
                  placeholder=""
                  rows={4}
                  className="width-full form-control"
                  disabled={data.withdrawStatus > 1 || user.userLevel === 2}
                  value={data.rejectedRemark ?? rejectReason}
                />
                <div className="font-r-12 color-error">
                  {errors.errRejectReason && (
                    <span>{errors.errRejectReason}</span>
                  )}
                </div>
              </div>
              <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                {data.withdrawStatus > 1 ? (
                  <SubmitButton
                    caption="Download"
                    buttonType="button"
                    color="btn-outline-success width-half"
                    disabled={posting}
                    onClick={() => handleVerifyDownload("")}
                  ></SubmitButton>
                ) : (
                  <>
                    {" "}
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                      <span className="font-b-16 color-black">Attachment</span>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                      <div className="input-group mb-3">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input form-control mb-1"
                            onChange={(event) =>
                              handleVerifyUploaderChange("R", event)
                            }
                            disabled={
                              data.withdrawStatus > 1 || user.userLevel === 2
                            }
                          />
                          <div className="font-r-12 color-error">
                            {errors.errVerifyUploader && (
                              <span>{errors.errVerifyUploader}</span>
                            )}
                          </div>
                          {verifyPreview &&
                            fileTypes.findIndex(
                              (item) => item === verifyUploader.name.substr(-4)
                            ) !== -1 && (
                              <img src={verifyPreview} alt={""} width="100" />
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                <span className="font-b-14 color-black">Balance Amount</span>
              </div>
              <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6">
                  <CurrencyInput
                    placeholder=""
                    decimalsLimit={2}
                    onValueChange={handleBalanceAmountChange}
                    prefix="Rs  "
                    className="font-r-14 width-full form-control text-right"
                    value={data.balanceAmount ?? verifyBalanceAmount}
                    disabled={data.withdrawStatus > 1 || user.userLevel === 2}
                  />
                  <div className="font-r-12 color-error">
                    {errors.errVerifyBalanceAmount && (
                      <span>{errors.errVerifyBalanceAmount}</span>
                    )}
                    {errors.errVerifyAmountCheck && (
                      <span>{errors.errVerifyAmountCheck}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row pb-1">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 mt-3">
                  <div className="font-r-12 color-error">
                    {errors.errVerifyUploader && (
                      <span>{errors.errVerifyUploader}</span>
                    )}
                  </div>
                  <div className="table-responsive">
                    <table className="table table-sm font-r-14 button-border ">
                      <thead className="table-info">
                        <tr>
                          <th scope="col" className=" bg-wheat">
                            Game Id
                          </th>
                          <th scope="col" className=" bg-wheat">
                            Withdraw Code
                          </th>
                          <th scope="col" className=" bg-wheat">
                            Game Type
                          </th>
                          <th scope="col" className=" bg-wheat">
                            Attachment
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {details.map((item, index) => (
                          <tr scope="row" key={index}>
                            <td>
                              <button
                                type="button"
                                className="table-button"
                                onClick={() => copyToClipboard(item.gameId)}
                              >
                                {item.gameId}
                              </button>
                            </td>
                            <td>
                              <button
                                type="button"
                                className="table-button"
                                onClick={() =>
                                  copyToClipboard(item.withdrawCode)
                                }
                              >
                                {item.withdrawCode}
                              </button>
                            </td>
                            <td>{item.gameTypeName}</td>
                            <td>
                              {data.withdrawStatus === 1 &&
                              user.userLevel === 1 ? (
                                <input
                                  type="file"
                                  className="custom-file-input form-control mb-1"
                                  onChange={(event) =>
                                    handleVerifyUploaderChange(item._id, event)
                                  }
                                />
                              ) : (
                                <SubmitButton
                                  caption="Download"
                                  buttonType="button"
                                  color="btn-outline-success"
                                  onClick={() => handleVerifyDownload(item._id)}
                                  disabled={posting}
                                ></SubmitButton>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
          {data.withdrawStatus === 1 && user.userLevel === 1 && (
            <div className="col-6 col-xs-6 col-sm-6 col-md-6">
              {isChecked ? (
                <SubmitButton
                  id="btnReject"
                  caption="Reject"
                  buttonType="button"
                  color="btn-danger width-full"
                  disabled={posting}
                  onClick={handleVerify}
                ></SubmitButton>
              ) : (
                <SubmitButton
                  caption="Verify"
                  buttonType="button"
                  color="btn-primary width-full"
                  onClick={handleVerify}
                  disabled={posting}
                ></SubmitButton>
              )}
            </div>
          )}
        </div>
      )}
      {/* withdraw/ redeposit judgment */}
      {data.withdrawStatus >= 2 && data.withdrawStatus < 6 && (
        <>
          {redepositDetails.map((itemData) => (
            <RedepositItem
              user={user}
              id={id}
              data={itemData}
              availableAmount={itemData.availableAmount}
            ></RedepositItem>
          ))}
          {(data.showNewRedepositPage ?? 0) === 1 && (
            <RedepositItem
              user={user}
              id={id}
              data={[]}
              redepositSeq={data.redepositSeq}
              availableAmount={data.newBalanceAmount ?? data.balanceAmount}
            ></RedepositItem>
          )}
        </>
      )}
      {/* withdraw complete or reject */}
      {data.withdrawStatus >= 2 &&
        (data.confirmRedepositAmount ?? 0) === 0 &&
        data.withdrawStatus < 6 && (
          <div className="container body round-edge button-border bg-accepted mt-2">
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <span className="font-b-18 color-black">
                Withdrawal Complete{" "}
              </span>
            </div>
            <div className="row text-left  pb-2">
              <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                <span className="font-b-16 color-black">Balance Amount</span>
              </div>
              <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                <CurrencyInput
                  placeholder=""
                  decimalsLimit={2}
                  onValueChange={handleCompleteBalanceAmount}
                  prefix="Rs "
                  className="font-r-16 width-half form-control text-right"
                  value={data.completeBalanceAmount ?? completeBalanceAmount}
                  disabled={data.withdrawStatus > 4 || user.userLevel == 2}
                />
                <div className="font-r-12 color-error">
                  {errors.errCompleteBalanceAmount && (
                    <span>{errors.errCompleteBalanceAmount}</span>
                  )}
                </div>
              </div>
              {data.withdrawStatus > 4 ? (
                <></>
              ) : (
                <>
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                    <span className="font-b-16 color-black">
                      Withdraw Receipt
                    </span>
                  </div>
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                    <div className="input-group mb-3">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input form-control mb-1"
                          onChange={handleCompleteReceiptUploaderChange}
                          disabled={user.userLevel == 2}
                        />
                        <div className="font-r-12 color-error">
                          {errors.errCompleteReceiptUploader && (
                            <span>{errors.errCompleteReceiptUploader}</span>
                          )}
                        </div>
                        {completeReceiptPreview &&
                          fileTypes.findIndex(
                            (item) =>
                              item === completeReceiptUploader.name.substr(-4)
                          ) !== -1 && (
                            <img
                              src={completeReceiptPreview}
                              alt={""}
                              width="100"
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* Reject */}
            </div>
            {data.withdrawStatus > 4 ? (
              <div className="col-4 col-xs-4 col-sm-4 col-md-4 pb-1">
                <SubmitButton
                  id="btnReceiptDownload"
                  caption="Receipt Download"
                  buttonType="button"
                  color="btn-outline-success"
                  onClick={handleReceiptDownload}
                  disabled={posting || data.completeBalanceAmount == undefined}
                ></SubmitButton>
              </div>
            ) : (
              <div className="col-4 col-xs-4 col-sm-4 col-md-4 pb-1">
                <SubmitButton
                  id="btnComplete"
                  caption="Complete"
                  buttonType="button"
                  color="btn-outline-success"
                  disabled={posting || user.userLevel === 2}
                  onClick={handleComplete}
                ></SubmitButton>
              </div>
            )}
          </div>
        )}
    </>
  );
};

export default Withdraw;
