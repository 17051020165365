import React, { useState, useEffect, useContext } from "react";
import CurrencyInput from "react-currency-input-field";
import SubmitButton from "../SubmitButton";
import {
  apiUrl,
  withdrawStatus,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  gameType,
} from "../../common/const";
import { notifySuccess, notifyError } from "../Toaster";
import { useNavigate } from "react-router-dom";
import imgCopy from "../../asset/img/copy.png";

const RedepositItem = ({ user, id, data, availableAmount, redepositSeq}) => {
  // ナヴィゲーションを生成
  const navigate = useNavigate();

  // エラーuseStateを設定
  const [errors, setErrors] = useState({});


  useEffect(() => {
    setConfirmWithdrawAmount(availableAmount);
  }, []);

  // confirm withdraw amount
  const [confirmWithdrawAmount, setConfirmWithdrawAmount] = useState("");
  // confirm re-deposit amount
  const [confirmRedepositAmount, setConfirmRedepositAmount] = useState(
    data?.confirmRedepositAmount ?? "0"
  );
  const [posting, setPosting] = useState(false);
  const [selectedRedepositGameType, setSelectedRedepositGameType] =
    useState("");
  const [redepositGameId, setRedepositGameId] = useState("");
  const [newBalanceAmount, setNewBalanceAmount] = useState("");
  const [reDepositReceiptUploader, setReDepositReceiptUploader] =
    useState(null);
  const [reDepositReceiptPreview, setReDepositReceiptPreview] = useState(null);
  // 非活性
  const [disabled, setDisabled] = useState(false);

  var errorConfirmWithdrawAmount =
    "Please enter the Confirmation Withdraw Amount";
  var errorConfirmRedepositAmount = "Please enter the Re-deposit Amount";
  var errorGreaterThanCheck = `amount is greater than`;
  var errorRedepositGameId = "Please enter the Re-deposit Game Id";
  var errorRedepositGameType = "Please select the Re-deposit Game Type";
  var errorNewBalanceAmount = "Please enter the new Balance Amount to Withdraw";
  var errorReDepositReceiptUploader = "Please upload the re-deposit attachment";

  const validateConfirm = () => {
    const newErrors = {};
    if (!confirmRedepositAmount)
      newErrors.errConfirmRedepositAmount = errorConfirmRedepositAmount;
    if (!confirmWithdrawAmount)
      newErrors.errConfirmWithdrawAmount = errorConfirmWithdrawAmount;
    if (confirmRedepositAmount > 0 ){
      if (!redepositGameId) newErrors.errRedepositGameId = errorRedepositGameId;
      if (!selectedRedepositGameType)
        newErrors.errRedepositGameType = errorRedepositGameType;
    }
    
    if (confirmRedepositAmount > availableAmount) {
      newErrors.errRedepositValueCheck = `Redeposit ${errorGreaterThanCheck} verify amount`;
    }
    if (confirmWithdrawAmount > availableAmount) {
      newErrors.errWithdrawValueCheck = `Withdraw ${errorGreaterThanCheck} verify amount`;
    }
    if (
      Number(confirmRedepositAmount) + Number(confirmWithdrawAmount) !=
      availableAmount
    ) {
      newErrors.errWithdrawNRepaymentValueCheck = `Total is must tall with balance amount`;
    }
    return newErrors;
  };

  const reDepositValidate = () => {
    const newErrors = {};
    if (!newBalanceAmount)
      newErrors.errNewBalanceAmount = errorNewBalanceAmount;
    if (!reDepositReceiptUploader)
      newErrors.errReDepositReceiptUploader = errorReDepositReceiptUploader;
    if (data?.confirmWithdrawAmount < newBalanceAmount)
      newErrors.errRedepositedAmountCheck = `Re-deposit ${errorGreaterThanCheck} available amount`;
    return newErrors;
  };

  const handleConfirmWithdrawAmountChange = (value) => {
    // 選択した値を設定
    setConfirmWithdrawAmount(value);
    // エラー変数を生成
    var error = "";
    var errorGreater = "";
    if (!value) {
      // エラーを付与
      error = errorConfirmWithdrawAmount;
    } else if (value > data?.balanceAmount) {
      errorGreater = `Redeposit ${errorGreaterThanCheck} verify amount`;
    }

    // エラーアレイに追加
    setErrors((prevErrors) => ({
      ...prevErrors,
      errConfirmWithdrawAmount: error,
      errWithdrawNRepaymentValueCheck: "",
      errWithdrawValueCheck: errorGreater,
    }));
  };

  const handleConfirmRedepositAmountChange = (value) => {
    // 選択した値を設定
    setConfirmRedepositAmount(value);
    // エラー変数を生成
    var error = "";
    var errorGreater = "";
    if (!value) {
      // エラーを付与
      error = errorConfirmRedepositAmount;
    } else if (value > data?.balanceAmount) {
      errorGreater = `Redeposit ${errorGreaterThanCheck} verify amount`;
    }

    // エラーアレイに追加
    setErrors((prevErrors) => ({
      ...prevErrors,
      errConfirmRedepositAmount: error,
      errWithdrawNRepaymentValueCheck: "",
      errRedepositValueCheck: errorGreater,
    }));
  };

  // withdraw, redeposit Confirm
  const handleAmountConfirmation = async () => {
    // prevent multiple clicks
    if (posting) return;

    // バリデーションを実行
    const validationErrors = validateConfirm();
    //　エラーアレイを判断
    if (Object.keys(validationErrors).length > 0) {
      // エラーを設定
      setErrors(validationErrors);
    } else {
      // エラーをクリアする
      setErrors({});

      // set posting to True
      setPosting(true);

      try {
        const response = await fetch(`${apiUrl}/withdraw/confirm`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: id,
            confirmWithdrawAmount: confirmWithdrawAmount,
            confirmRedepositAmount: confirmRedepositAmount,
            currentUser: { _id: user._id, userLevel: user.userLevel },
            redepositGameId: redepositGameId,
            redepositGameType: selectedRedepositGameType,
            redepositSeq: redepositSeq ?? 1,
            availableAmount: availableAmount
          }),
        });
        if (response.ok) {
          const reqData = await response.json();
          //setData(reqData);
          notifySuccess(MESSAGE_SUCCESS);
          navigate("/home");
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
        // set posting to False
        setPosting(false);
      }
    }
  };

  const handleNewBalanceAmountChange = (value) => {
    // 金額設定
    setNewBalanceAmount(value);
    // エラー変数を生成
    var error = "";
    var errorCheck = "";
    if (!value) {
      // エラーを付与
      error = errorNewBalanceAmount;
    } else if (data?.confirmRedepositAmount < value) {
      errorCheck = `Re-deposit ${errorGreaterThanCheck} requested amount`;
    }
  };

  const handleReDepositReceiptUploaderChange = (event) => {
    // 選択したファイルを抽出
    const file = event.target.files[0];
    // エラー変数を生成
    var error = "";
    // ファイルを設定
    setReDepositReceiptUploader(file);
    if (file) {
      // ファイルのオブジェクトを生成
      const objectUrl = URL.createObjectURL(file);
      setReDepositReceiptPreview(objectUrl);
    } else {
      // エラーを付与
      error = errorReDepositReceiptUploader;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({
      ...prevErrors,
      errReDepositReceiptUploader: error,
    }));
  };

  const handleReDeposit = async (e) => {
    // バリデーションを実行
    const validationErrors = reDepositValidate();
    //　エラーアレイを判断
    if (Object.keys(validationErrors).length > 0) {
      // エラーを設定
      setErrors(validationErrors);
    } else {
      // エラーをクリアする
      setErrors({});

      // prevent multiple clicks
      if (posting) return;

      // set posting to True
      setPosting(true);

      const formData = new FormData();
      formData.append("userId", user?._id);
      formData.append("id", id);
      formData.append("newBalanceAmount", newBalanceAmount);
      formData.append(
        "currentUser",
        JSON.stringify({ _id: user._id, userLevel: user.userLevel })
      );
      formData.append("itemId", data._id);
      formData.append("attachment", reDepositReceiptUploader);
      formData.append("redepositSeq", Number(redepositSeq ?? 0) + 1);
      
      try {
        const response = await fetch(`${apiUrl}/withdraw/redeposit`, {
          method: "POST",
          headers: { enctype: "multipart/form-data" },
          body: formData,
        });
        if (response.ok) {
          const reqData = await response.json();
          //setData(reqData);
          notifySuccess(MESSAGE_SUCCESS);
          navigate("/WithdrawList");
          if (reqData) {
            setDisabled(true);
          }
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
        setPosting(false);
      }
    }
  };

  const handleReDepositDownload = () => {
    // prevent multiple clicks
    if (posting) return;

    // set posting to True
    setPosting(true);

    fetch(`${apiUrl}/withdraw/redepositreceiptattachment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: data._id,
        userId: user?._id,
        currentUser: { _id: user._id, userLevel: user.userLevel },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          notifyError(MESSAGE_ERROR);
        }
        return response.blob(); // Expect a Blob as the response
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Re-Deposit_Receipt_GameId_${data?._id}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        notifySuccess("Download is successfull");
      })
      .catch((error) => {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      })
      .finally(() => {
        // set posting to False
        setPosting(false);
      });
  };

  const handleRedepositGameIdChange = (e) => {
    // 選択した値を設定
    setRedepositGameId(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorRedepositGameId;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, errRedepositGameId: error }));
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert(`${text} is copied`);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleRedepositGameTypeChange = (e) => {
    // 選択した値を設定
    setSelectedRedepositGameType(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorRedepositGameType;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, errRedepositGameType: error }));
  };
  // プレビューできるファイルタイプ
  const fileTypes = [".png", ".jpg", ".ico", ".bmp", "jpeg"];

  return (
    <>
      <div className="container body round-edge button-border mt-1">
        <div className="col-12 col-xs-12 col-sm-12 col-md-12">
          <span className="font-b-14 color-black">Bank Withdraw Amount</span>
        </div>
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 padding-right-20">
          <CurrencyInput
            placeholder=""
            decimalsLimit={2}
            onValueChange={handleConfirmWithdrawAmountChange}
            prefix="Rs  "
            className="font-r-14 width-half form-control text-right"
            value={data.confirmWithdrawAmount ?? confirmWithdrawAmount}
            disabled={(data.redepositStatus?? 0) > 0}
          />
        </div>
        <div className="font-r-12 color-error">
          <ul>
            {errors.errConfirmWithdrawAmount && (
              <li>
                <span>{errors.errConfirmWithdrawAmount}</span>
              </li>
            )}
            {errors.errWithdrawValueCheck && (
              <li>
                <span>{errors.errWithdrawValueCheck}</span>
              </li>
            )}
            {errors.errWithdrawNRepaymentValueCheck && (
              <li>
                <span>{errors.errWithdrawNRepaymentValueCheck}</span>
              </li>
            )}
          </ul>
        </div>
        {/* Game Id */}
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
          <span className="font-b-14 color-black">Game Id</span>
        </div>
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 padding-right-20 d-flex">
          <input
            type="text"
            className="font-r-14 width-half form-control"
            value={data?.redepositGameId ?? redepositGameId}
            onChange={handleRedepositGameIdChange}
            disabled={(data.redepositStatus?? 0) > 0}
          />
          <img
            id="btnCopy"
            src={imgCopy}
            className="round-edge white-border p0-important ms-1"
            onClick={() =>
              copyToClipboard(data?.redepositGameId ?? redepositGameId)
            }
            alt=""
            style={{ verticalAlign: "1px", cursor: "pointer" }}
          />
        </div>
        <div className="font-r-12 color-error">
          {errors.errRedepositGameId && (
            <span>{errors.errRedepositGameId}</span>
          )}
        </div>
        {/* game Type */}
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
          <span className="font-b-14 color-black">Game Type</span>
        </div>
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 padding-right-20">
          <select
            className="form-select font-r-14 width-half py-1"
            value={data?.redepositGameType ?? selectedRedepositGameType}
            onChange={handleRedepositGameTypeChange}
            disabled={(data.redepositStatus?? 0) > 0}
          >
            <option value="">Select an option</option>
            {gameType.map((item, index) => (
              <option value={item.key}>{item.value}</option>
            ))}
          </select>
          <div className="font-r-12 color-error">
            {errors.errRedepositGameType && (
              <span>{errors.errRedepositGameType}</span>
            )}
          </div>
        </div>
        <div className="col-12 col-xs-12 col-sm-12 col-md-12">
          <span className="font-b-14 color-black">Re-deposit Amount</span>
        </div>
        <div className="row pb-1">
          <div className="col-6 col-xs-6 col-sm-6 col-md-6">
            <CurrencyInput
              placeholder=""
              decimalsLimit={2}
              onValueChange={handleConfirmRedepositAmountChange}
              prefix="Rs  "
              className="font-r-14 width-full form-control text-right"
              value={data?.confirmRedepositAmount ?? confirmRedepositAmount}
              disabled={(data.redepositStatus?? 0) > 0}
            />
          </div>
          {((data.redepositStatus?? 0) === 0) && (
            <div className="col-6 col-xs-6 col-sm-6 col-md-6">
              <SubmitButton
                id="btnAdd"
                caption="Confirm"
                buttonType="button"
                color="btn-outline-primary width-full"
                onClick={handleAmountConfirmation}
                disabled={posting}
              ></SubmitButton>
            </div>
          )}
        </div>
        <div className="font-r-12 color-error">
          <ul>
            {errors.errConfirmRedepositAmount && (
              <li>
                <span>{errors.errConfirmRedepositAmount}</span>
              </li>
            )}
            {errors.errRedepositValueCheck && (
              <li>
                <span>{errors.errRedepositValueCheck}</span>
              </li>
            )}
            {errors.errWithdrawNRepaymentValueCheck && (
              <li>
                <span>{errors.errWithdrawNRepaymentValueCheck}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
      {((data.confirmRedepositAmount?? 0) > 0) && (
      <div className="container body round-edge button-border mt-2">
        {/* new balance amount */}
        <div className="col-12 col-xs-12 col-sm-12 col-md-12">
          <span className="font-b-14 color-black">New Balance Amount</span>
        </div>
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 padding-right-20">
          <CurrencyInput
            placeholder=""
            decimalsLimit={2}
            onValueChange={handleNewBalanceAmountChange}
            prefix="Rs  "
            className="font-r-14 width-half form-control text-right"
            value={data?.newBalanceAmount ?? newBalanceAmount}
            disabled={((data.redepositStatus?? 0) > 1) || user.userLevel === 2}
          />
        </div>
        <div className="font-r-12 color-error">
          {errors.errNewBalanceAmount && (
            <span>{errors.errNewBalanceAmount}</span>
          )}
          {errors.errRedepositedAmountCheck && (
            <span>{errors.errRedepositedAmountCheck}</span>
          )}
        </div>
        {/* re deposit uploader */}
        {((data.redepositStatus?? 0) === 1) && (
          <>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <span className="font-b-16 color-black">Re-Deposit Receipt</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <div className="input-group mb-3">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input form-control mb-1"
                    onChange={handleReDepositReceiptUploaderChange}
                    disabled={user.userLevel === 2}
                  />
                  <div className="font-r-12 color-error">
                    {errors.errReDepositReceiptUploader && (
                      <span>{errors.errReDepositReceiptUploader}</span>
                    )}
                  </div>
                  {reDepositReceiptPreview &&
                    fileTypes.findIndex(
                      (item) =>
                        item === reDepositReceiptUploader.name.substr(-4)
                    ) !== -1 && (
                      <img src={reDepositReceiptPreview} alt={""} width="100" />
                    )}
                </div>
              </div>
            </div>
          </>
        )}
        {/* re-deposit button */}
        {((data.redepositStatus?? 0) === 1)  ? (
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 pb-1">
            <SubmitButton
              id="btnAdd"
              caption="Re-Deposit"
              buttonType="button"
              color="btn-outline-primary width-half"
              onClick={handleReDeposit}
              disabled={posting || user.userLevel === 2}
            ></SubmitButton>
          </div>
        ) : (
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 pb-1">
            <SubmitButton
              caption="Re-Deposit Download"
              buttonType="button"
              color="btn-outline-success width-half"
              onClick={handleReDepositDownload}
            ></SubmitButton>
          </div>
        )}
      </div>
      )}
    </>
  );
};

export default RedepositItem;
