import React, { useState, useContext } from "react";
import SubHeader from "../SubHeader";
import SubmitButton from "../SubmitButton";
import { UserContext } from "../../context/UserContext";
import { apiUrl } from "../../common/const";
import imgCopy from "../../asset/img/copy.png";
import { useNavigate } from 'react-router-dom';

const CreateUserLink = () => {
  const [txtTelephoneNo, setTxtTelephoneNo] = useState("");
  // エラーuseStateを設定
  const [errors, setErrors] = useState({});
  // psotbackエラー
  const [error, setError] = useState(null);
  // 登録リンク
  const [txtRegisterLink, setTxtRegisterLink] = useState("");

  // ナヴィゲーションを生成
  const navigate = useNavigate();
  //////////////USER ///////////////////
  const { user } = useContext(UserContext);
  if (!user) {
    navigate("/");
  }
  //////////////USER ///////////////////

  const errorTelephoneNo = "Please enter the Telephone No";

  // 金額のハンドラーを設定
  const handleTxtTelephoneChange = (e) => {
    // 電話番号設定
    setTxtTelephoneNo(e.target.value);
    setTxtRegisterLink('');
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorTelephoneNo;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, txtTelephoneNo: error }));
  };

  // ボタンクリック
  const handleGenerateLinkClick = async (e) => {
    // バリデーションを実行
    const validationErrors = completeValidate();
    //　エラーアレイを判断
    if (Object.keys(validationErrors).length > 0) {
      // エラーを設定
      setErrors(validationErrors);
    } else {
      // エラーをクリアする
      setErrors({});
      try {
        const response = await fetch(`${apiUrl}/user/createlink`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            telephoneNo: txtTelephoneNo,
            currentUser: user,
          }),
        });
        if (response.ok) {
          const reqData = await response.json();
          const data = reqData.result;
          // create the link
          setTxtRegisterLink(
            `${window.location.origin}/registration?id=${data._id}`
          );
        }
      } catch (error) {
        setError(error.message);
      } finally {
        //setLoading(false);
      }
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(txtRegisterLink);
      alert("Registration Link is copied");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const completeValidate = () => {
    const newErrors = {};
    if (!txtTelephoneNo) newErrors.txtTelephoneNo = errorTelephoneNo;
    return newErrors;
  };

  if (error) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-danger" role="alert">
              {JSON.stringify(error)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form>
      <div className="container body round-edge button-border">
        <div className="row text-left ">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <SubHeader title="Registration Link "></SubHeader>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
            <span className="font-b-16 color-black">Client Telephone No</span>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <input
              type="text"
              className="font-r-16 width-half form-control"
              value={txtTelephoneNo}
              onChange={handleTxtTelephoneChange}
            />
          </div>
          <div className="font-r-12 color-error">
            {errors.txtTelephoneNo && <span>{errors.txtTelephoneNo}</span>}
          </div>
          <div className="col-4 col-xs-4 col-sm-4 col-md-4 pb-2">
            <SubmitButton
              id="btnCancel"
              caption="Generate Link"
              buttonType="button"
              color="btn-outline-success"
              onClick={handleGenerateLinkClick}
            ></SubmitButton>
          </div>
          {txtRegisterLink === "" ? (
            <></>
          ) : (
            <>
              <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
                <span className="font-b-16 color-black">Registration Link</span>
              </div>
              <div className="row">
                <div className="col-8 col-xs-8 col-sm-8 col-md-8 mb-2">
                  <input
                    type="text"
                    className="font-r-16 width-full form-control"
                    value={txtRegisterLink}
                  />
                </div>
                <div className="col-4 col-xs-4 col-sm-4 col-md-4 mb-2">
                  <img
                    id="btnCopy"
                    src={imgCopy}
                    className="round-edge white-border p-2"
                    onClick={copyToClipboard}
                    alt=""
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
};

export default CreateUserLink;
