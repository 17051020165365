import React from "react";
import imgHome from "../asset/img/home-32.png";
import { useNavigate } from "react-router-dom";

const SubHeader = ({ title }) => {
  // ナヴィゲーションを生成
  const navigate = useNavigate();

  const onClickHandlerImgHome = () => {
    navigate('/home');
  };

  return (
    <div className="row">
      <div className="col-12 col-xs-12 col-sm-12 col-md-12 ps-0">
        <div className="card-header bg-header round-topedge">
          <span>{title}</span>
          {/* <img
            id="btnHome"
            src={imgHome}
            className="round-edge white-border p-0 mt-1"
            alt=""
            style={{ width: "32px", float:"right"}}
            onClick={onClickHandlerImgHome}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
