import React, { useContext } from "react";
import "../common.css";
import { withdrawStatusColor, withdrawStatus, dateFormat } from "../../common/const";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { format } from 'date-fns';

const WithdrawGridList = ({ color, font, item, index }) => {
  const Class = `round-edge header m-1 width-full white-border ${color} ${font}`;

  //////////////USER ///////////////////
  const { user } = useContext(UserContext);
  //////////////USER ///////////////////

  // ナヴィゲーションを生成
  const navigate = useNavigate();

  var badgeClass = "position-sticky start-100 transform-badge badge";
  var badgetext = "";
  var updatedDatetxt = "";

  if (item.withdrawStatus === 1) {
    badgeClass = badgeClass + " bg-new";
  } else if (item.withdrawStatus === 2) {
    badgeClass = badgeClass + " bg-done";
  } else if (item.withdrawStatus === 3) {
    badgeClass = badgeClass + " bg-reject";
  } else if (item.withdrawStatus >= 4) {
    badgeClass = badgeClass + " bg-verify";
  }

  updatedDatetxt = `${badgetext} Last Update Time:  ${format(item.lastUpdateDate,dateFormat)}`;

 const withdrawedDateformated = Date.now();

  const handleOnclick = async (e) => {
    // redirect to the withdraw form with selected withdraw id/ customer id / user
    navigate(`/withdraw`, {
      state: { id: item._id, userId: item.userId, currentUser: user },
    });
  };
  return (
    <button className={Class} onClick={handleOnclick} id={`btn_${index}`}>
      <div className="row bg-header round-topedge ">
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 d-flex min-hight-25">
          <span className="font-b-14 text-left">
            {`Game Id: ${item.gameId ?? ""}`}
          </span>
          <span className="font-b-14 text-left" style={{paddingLeft:"100px"}}>
            {`Tel No: ${user.telephoneNo ?? ""}`}
          </span>
          <span
            className={`position-sticky start-100 transform-badge badge ${
              withdrawStatusColor[item.withdrawStatus] } with-100px `}
              style={{maxHeight:'26px', lineHeight:'13px'}}
          >
            {withdrawStatus[item.withdrawStatus]}
          </span>
        </div>
      </div>
      <div className={`row ${item.isRead == 0 ? 'unread': ''}`}>
        <div className="col-6 col-xs-6 col-sm-6 col-md-6 d-flex">
          <p className="font-r-14 text-left m-0">{`Amount: ${
            item.amount == undefined ? "" : item.amount
          }`}</p>
        </div>
        <div className="col-6 col-xs-6 col-sm-6 col-md-6 d-flex">
          <p className="font-r-14 text-left m-0"></p>
        </div>
      </div>
      <div className={`row ${item.isRead == 0 ? 'unread': ''}`}>
        <div className="col-6 col-xs-6 col-sm-6 col-md-6 d-flex">
          <p className="font-r-14 text-left m-0">{`Withdraw. Date: ${
            item.withdrawedDate == undefined ? "" : format(item.withdrawedDate,dateFormat) 
          }`}</p>
        </div>
        <div className="col-6 col-xs-6 col-sm-6 col-md-6 d-flex">
          <p className="font-r-14 text-left m-0">{updatedDatetxt}</p>
        </div>
      </div>
      <div className={`row ${item.isRead == 0 ? 'unread': ''}`}>
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 d-flex">
          <p className="font-r-14 text-left m-0">{`Special Remark: ${
            item.specialRemark == undefined ? "" : item.specialRemark
          }`}</p>
        </div>
      </div>
    </button>
  );
};

export default WithdrawGridList;
