import React from 'react';
import PropTypes from 'prop-types';
import './ImageButton.css';  


const ImageButton = ({ imageUrl, title, onClick }) => {
  return (
    <div className='container p-2'>
    <button className='custom-button round-edge' onClick={onClick}>
      <div >
        <img src={imageUrl} className="button-image" />
      </div>
      <div className="button-text font-b-12">{title}</div>
    </button>
    </div>
  );
};

ImageButton.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ImageButton;