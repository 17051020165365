import React, { useContext, useState, useEffect } from "react";
import ButtonList from "./ButtonList";
import NotifyItem from "./NotifyItem";
import { UserContext } from "../context/UserContext";
import {
  apiUrl,
  depositStatusColor,
  depositStatusShort,
  depositStatus,
  withdrawStatusShort,
  withdrawStatus,
  withdrawStatusColor,
} from "../common/const";
import { notifyError, notifyInfo, notifySuccess } from "./Toaster";
import { useNavigate } from "react-router-dom";
//import { SocketContext } from "../App";
import imgRefresh from "../asset/img/refresh.png";

const Home = () => {
  // ナヴィゲーションを生成
  const navigate = useNavigate();

  //////////////USER ///////////////////
  const { user } = useContext(UserContext);
  //////////////USER ///////////////////

  /////////// SOCKET /////////////////
  // const socket = useContext(SocketContext);
  // const [notifications, setNotifications] = useState([]);

  // useEffect(() => {
  //   // Listen for 'notification' events from the server
  //   socket.on("notification", (message) => {
      
  //     setNotifications((prev) => [...prev, JSON.stringify(message)]);
  //   });

  //   // Cleanup the listener when component unmounts
  //   return () => {
  //     socket.off("notification");
  //   };
  // }, [socket]);
  /////////// SOCKET /////////////////

  // set state for notification items
  const [notifyItem, setNotifyItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/deposit/homelist`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          currentUser: user,
          limit: 5,
        }),
      });

      if (!response.ok) {
        const { message } = result;
        if (message) throw new Error(message);
        else throw new Error("Unexpected error...");
      }

      const result = await response.json();
      setNotifyItem(result);
      
    } catch (error) {
      setError(error.message);
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // デポジットデータを取得
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
    else {
    // Call the fetchData function
    fetchData();
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  if (loading) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-success" role="alert">
              Data is loading....
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-danger" role="alert">
              Unexpected error....
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <ul>
        {notifications.map((notification, index) => (
          <li key={index}>{notification}</li>
        ))}
      </ul> */}
      <div className="container pb-1 ">
        <div className="row">
          <div className="col">
            <ul className="list-group">             
              <NotifyItem
                indexid="first"
                notification="Notification"
                isnew=""
                color="bg-header"
                font="font-b-16"
              ></NotifyItem>
                <img
                  id="btnHome"
                  src={imgRefresh}
                  className="round-edge white-border p-0 img-fluid mt-1"
                  alt=""
                  style={{ width: "32px" , position:'absolute', right:'18%',}}
                  onClick={fetchData}
                />
              {notifyItem.map((item, index) => (
                <NotifyItem
                indexid={index}
                  notification={`${item.type} - Game Id: ${
                    item.gameId ?? ""
                  }, Amount: ${new Intl.NumberFormat().format(item.amount)}`}
                  isnew={
                    item.type[0] === "D"
                      ? depositStatus[item.tStatus]
                      : withdrawStatus[item.tStatus]
                  }
                  font="font-r-16"
                  color="bg-white"
                  badgeClass={
                    item.type[0] === "D"
                      ? depositStatusColor[item.tStatus]
                      : withdrawStatusColor[item.tStatus]
                  }
                  objitem={item}
                  isReadStyle={item.isRead == 0 ? 'unread': ''}
                ></NotifyItem>
              ))}
              ;
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <ButtonList userLevel={user.userLevel}></ButtonList>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
