import React from "react";
import PropTypes from "prop-types";
import ImageButton from "./ImageButton";
import "bootstrap/dist/css/bootstrap.min.css";
import Deposit from "../asset/img/deposit-32.png";
import Withdw from "../asset/img/transfer-32.png";
import Re_Deposit from "../asset/img/re-deport-32.png";
import Chat from "../asset/img/chat-32.png";
import { useNavigate } from "react-router-dom";
import iconUserCreation from "../asset/img/user-create-32.png";
import iconContactUs from "../asset/img/contact-us-32.png";
import iconList from "../asset/img/list-32.png";
import completeList from "../asset/img/listComplete-32.png";
import {WHATSAPP_URL} from "../common/const";

const ButtonList = (userLevel) => {
  // ナヴィゲーションを生成
  const navigate = useNavigate();

  // デポジットページを開く
  const handleDepositClick = () => {
    navigate("/Deposit");
  };
  // デポジットページを開く
  const handleDepositListClick = () => {
    navigate("/DepositList");
  };

  const handleCreateUserLinkClick = () => {
    navigate("/userlink");
  };

  const handleChatClick = () => {
    window.open(`${WHATSAPP_URL}`, "_blank");
  };

  const handleWithdrawClick = () => {
    navigate(`/withdraw`);
  };

  const handleWithdrawListClick = () => {
    navigate("/withdrawList");
  };

  const handleContactClick = () => {
    navigate("/banner");
  };

  const handleWithdrawCompletingListClick = () => {
    navigate("/withdrawcompleting");
  };

  const handleUserListClick = () => {
    navigate("/userList");
  };

  return (
    <div className="container">
      <div className="row p-0">
        <div className="col-xs-3 col-sm-3 col-md-3 col-3 p-0">
          <ImageButton
            title="Deposite"
            imageUrl={Deposit}
            onClick={handleDepositClick}
          ></ImageButton>
        </div>
        <div className="col-xs-3 col-sm-3 col-md-3 col-3 p-0">
          <ImageButton
            title="Withdraw"
            imageUrl={Withdw}
            onClick={handleWithdrawClick}
          ></ImageButton>
        </div>
        <div className="col-xs-3 col-sm-3 col-md-3 col-3 p-0">
          <ImageButton
            title="Chat"
            imageUrl={Chat}
            onClick={handleChatClick}
          ></ImageButton>
        </div>
        <div className="col-xs-3 col-sm-3 col-md-3 col-3 p-0">
          <ImageButton
            title="Contacts"
            imageUrl={iconContactUs}
            onClick={handleContactClick}
          ></ImageButton>
        </div>
      </div>

      <div className="row p-0">
        <div className="col-xs-3 col-sm-3 col-md-3 col-3 p-0">
          <ImageButton
            title="Deposite list"
            imageUrl={iconList}
            onClick={handleDepositListClick}
          ></ImageButton>
        </div>
        <div className="col-xs-3 col-sm-3 col-md-3 col-3 p-0">
          <ImageButton
            title="Withdraw list"
            imageUrl={iconList}
            onClick={handleWithdrawListClick}
          ></ImageButton>
        </div>
        {userLevel.userLevel === 1 ? (
          <>
            <div className="col-xs-3 col-sm-3 col-md-3 col-3 p-0">
              <ImageButton
                title="Withdraw Completing"
                imageUrl={completeList}
                onClick={handleWithdrawCompletingListClick}
              ></ImageButton>
            </div>
            <div className="col-xs-3 col-sm-3 col-md-3 col-3 p-0">
              <ImageButton
                title="Create User"
                imageUrl={iconUserCreation}
                onClick={handleCreateUserLinkClick}
              ></ImageButton>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="row p-0">
        {userLevel.userLevel === 1 ? (
          <>
            <div className="col-xs-3 col-sm-3 col-md-3 col-3 p-0">
              <ImageButton
                title="User List"
                imageUrl={completeList}
                onClick={handleUserListClick}
              ></ImageButton>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ButtonList;
