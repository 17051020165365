// BannerCarousel.jsx
import React, { useState, useRef  } from "react";
import "./styles.css";
import iconNext from "../../asset/img/next.png";
import iconPrevious from "../../asset/img/previous.png";
import {banners} from '../../common/const';


const BannerCarousel = () => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const carouselRef = useRef(null);

  // Handler for Mouse Down / Touch Start
  const handleDragStart = (e) => {
    setIsDragging(true);
    const position = e.type === 'mousedown' ? e.clientX : e.touches[0].clientX;
    setStartPosition(position);
  };

  // Handler for Mouse Move / Touch Move
  const handleDragMove = (e) => {
    if (!isDragging) return;

    const currentPosition = e.type === 'mousemove' ? e.clientX : e.touches[0].clientX;
    const difference = startPosition - currentPosition;

    if (difference > 100) {
      handleNext();
      setIsDragging(false);
    } else if (difference < -100) {
      handlePrevious();
      setIsDragging(false);
    }
  };

  // Handler for Mouse Up / Touch End
  const handleDragEnd = () => {
    setIsDragging(false);
  };

  // Handler for Next Button
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
  };

  // Handler for Previous Button
  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + banners.length) % banners.length
    );
  };

  return (
    <div className="container body round-edge button-border p-0 m-0">
      <div
        ref={carouselRef}
        className="banner-carousel p-0"
        onMouseDown={handleDragStart}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
        onTouchStart={handleDragStart}
        onTouchMove={handleDragMove}
        onTouchEnd={handleDragEnd}
      >
        <div className="banner-container p-0">
          <button onClick={handlePrevious} className="btn">
            <img src={iconPrevious} style={{ width: "24px" }}  alt="Previous"/>
          </button>
          <img src={banners[currentIndex].image} className="banner-image" alt="Contacts"/>
          <button onClick={handleNext} className="btn">
            <img src={iconNext} style={{ width: "24px" }}  alt="Next" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BannerCarousel;
