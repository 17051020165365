import React, { useState, useContext, useEffect } from "react";
import Logo from "../asset/img/logo.png";
import User from "../asset/img/user-32.png";
import Notification from "../asset/img/notification-32.png";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import imgHome from "../asset/img/home-32.png";
import { HOTLINE_NO } from "../common/const";

const Headernav = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // ナヴィゲーションを生成
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  // ローゴーのクリック、homeページを設定
  const handleLogoClick = () => {
    navigate("/home");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleProfileClick = () => {
    setDropdownOpen(!dropdownOpen);
    navigate(`/user`); // Redirect to the user page
  };

  
  const handleLogoutClick = () => {
    setDropdownOpen(!dropdownOpen);
    setUser(null);
    navigate(`/`); // Redirect to the user page
  }

  // 戻り値
  return (
    <header className="py-3 header round-edge">
      <div className="container p-0">
        <div className="row ">
          <div className="col-xs-8 col-am-8 col-md-8 col-8 text-left d-flex">
            <img
              src={Logo}
              style={{ width: "80px", height: "80px" }}
              onClick={handleLogoClick}
            />
            <p className="mb-0 fs-4 text-left pt-3">Supun Service</p>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-4 d-flex justify-content-end align-items-center pr-2 ">
            {/* <button
              type="button"
              className="btn position-relative p-0 me-4 width-32"
            >
              <img
                src={Notification}
                roundedcircle="true"
                style={{ width: "32px", height: "32px", marginRight: "10px" }}
              />
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                9
              </span>
            </button> */}
            <button
              type="button"
              className="btn m-0 p-0"
              onClick={handleLogoClick}
            >
              <img
                id="btnHome"
                src={imgHome}
                className="round-edge white-border p-0 img-fluid"
                alt=""
                style={{ width: "32px" }}
              />
            </button>
            <button
              type="button"
              className="btn position-relative p-0 me-4 width-32"
              onClick={toggleDropdown}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src={User}
                alt=""
                roundedcircle="true"
                style={{ width: "32px", height: "32px", marginRight: "10px" }}
              />
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu dropdown-menu-right mt-4">
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={handleProfileClick}
                >
                  Profile
                </button>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={handleLogoutClick}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12" style={{lineHeight:"0px"}}> 
            <span className="text-left p-2 font-r-16">
              Hotline : {HOTLINE_NO}
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Headernav;
