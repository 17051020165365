import React, { useState, useEffect, useContext } from "react";
import CurrencyInput from "react-currency-input-field";
import SubHeader from "./SubHeader";
import SubmitButton from "./SubmitButton";
import {
  apiUrl,
  depositStatus,
  gameType,
  depositTypes,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  WHATSAPP_URL,
  CommonWhatsAppNumber,
} from "../common/const";
import { useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { notifySuccess, notifyError, notifyInfo } from "./Toaster";
import imgCopy from "../asset/img/copy.png";
import imgChat from "../asset/img/chat-32.png";
import { useNavigate } from "react-router-dom";

const Deposit = () => {
  const location = useLocation();
  const { id, userId, currentUser } = location.state || {};

  // ナヴィゲーションを生成
  const navigate = useNavigate();

  //////////////USER ///////////////////
  const { user } = useContext(UserContext);
  //////////////USER ///////////////////

  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [receiptDisabled, setReceiptDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [posting, setPosting] = useState(false);

  // ダウンロード
  const handleDownload = () => {
    // prevent multiple clicks
    if (posting) return;

    try {
      // set posting to True
      setPosting(true);

      fetch(`${apiUrl}/deposit/attachment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          userId: userId,
          currentUser: user,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            notifyError(MESSAGE_ERROR);
          }
          return response.blob(); // Expect a Blob as the response
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `Deposit_GameId_${data.gameId}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          notifySuccess("Download is successfull");
        })
        .catch((error) => {
          const { message } = error;
          notifyError(message ?? MESSAGE_ERROR);
        })
        .finally(() => {
          // set posting to False
          setPosting(false);
        });
    } catch (error) {
      const { message } = error;
      notifyError(message ?? MESSAGE_ERROR);
    }
  };

  // Receipt ダウンロード
  const handleReceiptDownload = () => {
    // prevent multiple clicks
    if (posting) return;

    // set posting to True
    setPosting(true);

    fetch(`${apiUrl}/deposit/receiptattachment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        userId: userId,
        currentUser: user,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          notifyError(MESSAGE_ERROR);
        }
        return response.blob(); // Expect a Blob as the response
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Deposited_Receipt_GameId_${data.gameId}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        notifySuccess("Download is successfull");
      })
      .catch((error) => {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      })
      .finally(() => {
        // set posting to False
        setPosting(false);
      });
  };

  // ダウンロード
  const handleVerify = async () => {
    // prevent multiple clicks
    if (posting) return;

    // set posting to True
    setPosting(true);

    try {
      const response = await fetch(`${apiUrl}/deposit/verify`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: id,
          userId: userId,
          currentUser: user,
        }),
      });
      if (response.ok) {
        const reqData = await response.json();
        setData(reqData);
        notifySuccess(MESSAGE_SUCCESS);
        //navigate("/DepositList");
      }
    } catch (error) {
      const { message } = error;
      notifyError(message ?? MESSAGE_ERROR);
    } finally {
      // set posting to False
      setPosting(false);
    }
  };

  // 特別記事のuseStateを設定
  const [txtSpecialRemarks, setTxtSpecialRemarks] = useState("");
  // 金額のuseStateを設定
  const [txtAmount, setTxtAmount] = useState("");
  // デポジットタイプのuseStateを設定
  const [drpDepositType, setDrpDepositType] = useState("");
  // ゲームタイプ
  const [drpGameType, setDrpGameType] = useState("");
  // ファイルアップロードのuseStateを設定
  const [fileUploader, setFileUploader] = useState(null);
  // エラーuseStateを設定
  const [errors, setErrors] = useState({});
  // アップロードしたファイルを開くのuseStateを設定
  const [preview, setPreview] = useState(null);
  // ゲームID
  const [gameId, setGameId] = useState("");

  const [selectedOption, setSelectedOption] = useState("");
  const [balanceAmount, setBalanceAmount] = useState("0");
  const [txtRejectReason, setTxtRejectReason] = useState("");
  const [receiptUploader, setReceiptUploader] = useState(null);
  const [receiptPreview, setReceiptPreview] = useState(null);

  // エラーメッセージを設定
  var errorDepositType = "Please select the Deposit Type";
  var errorGameType = "Please select the Game Type";
  var errorDepositAmount = "Please enter the Deposited Amount";
  var errorAttachedFile = "Please attached the file";
  var errorBalanceAmount = "Please enter Balance Amount";
  var errorRejectReason = "Please enter Reject Reason";
  var errorReceiptFile = "Please attached the Receipt File";
  var errorGameId = "Please enter the Game Id";

  useEffect(() => {
    const fetchData = async () => {
      // prevent multiple clicks
      if (posting) return;

      // set posting to True
      setPosting(true);

      try {
        setLoading(true);

        const response = await fetch(`${apiUrl}/deposit/this`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: id,
            userId: userId,
            currentUser: user,
          }),
        });
        if (response.ok) {
          const reqData = await response.json();
          setData(reqData);
          if (reqData) {
            setDisabled(true);
            if (reqData.depositStatus === 2 || reqData.depositStatus === 3) {
              setReceiptDisabled(true);
              setSelectedOption(reqData.depositStatus);
            }
          }
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
        setLoading(false);
        setPosting(false);
      }
    };
    // Call the fetchData function
    if (!user) {
      navigate("/");
    } else {
      if (id) {
        fetchData();
      }
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  // deposit complete or reject
  const handleCompleteSubmit = async (e) => {
    // Prevent default form submission
    e.preventDefault();
    // バリデーションを実行
    const validationErrors = completeValidate();
    //　エラーアレイを判断
    if (Object.keys(validationErrors).length > 0) {
      // エラーを設定
      setErrors(validationErrors);
    } else {
      // エラーをクリアする
      setErrors({});

      // prevent multiple clicks
      if (posting) return;

      // set posting to True
      setPosting(true);

      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("id", id);
      formData.append("depositStatus", selectedOption);
      formData.append(
        "reference",
        selectedOption == 2 ? balanceAmount : txtRejectReason
      );
      formData.append("currentUser", JSON.stringify(user));
      formData.append("attachment", receiptUploader);
      try {
        const response = await fetch(`${apiUrl}/deposit/complete`, {
          method: "POST",
          headers: { enctype: "multipart/form-data" },
          body: formData,
        });
        if (response.ok) {
          const reqData = await response.json();
          setData(reqData);
          notifySuccess(MESSAGE_SUCCESS);
          navigate("/DepositList");
          if (reqData) {
            setDisabled(true);
            if (reqData.depositStatus === 2 || reqData.depositStatus === 3) {
              setReceiptDisabled(true);
              setSelectedOption(reqData.depositStatus);
            }
          }
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
        setPosting(false);
      }
    }
  };

  // 特別記事のハンドラーを設定
  const handleTxtSpecialRemarksChange = (e) =>
    setTxtSpecialRemarks(e.target.value);

  // 金額のハンドラーを設定
  const handleTxtAmountChange = (value) => {
    // 金額設定
    setTxtAmount(value);
    // エラー変数を生成
    var error = "";
    if (!value) {
      // エラーを付与
      error = errorDepositAmount;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, txtAmount: error }));
  };

  // デポジットタイプのハンドラーを設定
  const handleTxtGameIdChange = (e) => {
    // 選択した値を設定
    setGameId(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorGameId;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, gameId: error }));
  };

  // デポジットタイプのハンドラーを設定
  const handleDropdownChange = (e) => {
    // 選択した値を設定
    setDrpDepositType(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorDepositType;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, drpDepositType: error }));
  };

  const handleDropdownGameChange = (e) => {
    // 選択した値を設定
    setDrpGameType(e.target.value);
    // エラー変数を生成
    var error = "";
    if (!e.target.value) {
      // エラーを付与
      error = errorGameType;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, drpGameType: error }));
  };

  // ファイルアップロードのハンドラーを設定
  const handleFileUploaderChange = (event) => {
    // 選択したファイルを抽出
    const file = event.target.files[0];
    // エラー変数を生成
    var error = "";
    // ファイルを設定
    setFileUploader(file);
    if (file) {
      // ファイルのオブジェクトを生成
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    } else {
      // エラーを付与
      error = errorAttachedFile;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, fileUploader: error }));
  };

  const handleReceiptUploaderChange = (event) => {
    // 選択したファイルを抽出
    const file = event.target.files[0];
    // エラー変数を生成
    var error = "";
    // ファイルを設定
    setReceiptUploader(file);
    if (file) {
      // ファイルのオブジェクトを生成
      const objectUrl = URL.createObjectURL(file);
      setReceiptPreview(objectUrl);
    } else {
      // エラーを付与
      error = errorReceiptFile;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, receiptUploader: error }));
  };

  // バリデーションを設定
  const validate = () => {
    const newErrors = {};
    if (!gameId) newErrors.gameId = errorGameId;
    if (!drpDepositType) newErrors.drpDepositType = errorDepositType;
    if (!drpGameType) newErrors.drpGameType = errorGameType;
    if (!txtAmount) newErrors.txtAmount = errorDepositAmount;
    if (!fileUploader) newErrors.fileUploader = errorAttachedFile;
    return newErrors;
  };

  const completeValidate = () => {
    const newErrors = {};
    if (selectedOption == 2) {
      if (!balanceAmount) newErrors.balanceAmount = errorBalanceAmount;
      if (!receiptUploader) newErrors.receiptUploader = errorReceiptFile;
    }
    if (selectedOption == 3) {
      if (!txtRejectReason) newErrors.rejectReason = errorRejectReason;
    }
    return newErrors;
  };

  // サブミット―を設定
  const handleSubmit = async (e) => {
    // Prevent default form submission
    e.preventDefault();
    // バリデーションを実行
    const validationErrors = validate();
    //　エラーアレイを判断
    if (Object.keys(validationErrors).length > 0) {
      // エラーを設定
      setErrors(validationErrors);
    } else {
      // エラーをクリアする
      setErrors({});

      // prevent multiple clicks
      if (posting) return;

      // set posting to True
      setPosting(true);

      // フォームデータを作成
      const formData = new FormData();
      formData.append("userId", user._id);
      formData.append("gameId", gameId);
      formData.append("depositType", drpDepositType);
      formData.append("gameType", drpGameType);
      formData.append("amount", txtAmount);
      formData.append("specialRemark", txtSpecialRemarks);
      formData.append("attachment", fileUploader);
      formData.append("depositStatus", 1);
      formData.append("currentUser", JSON.stringify(user));

      try {
        const response = await fetch(`${apiUrl}/deposit/save`, {
          method: "POST",
          headers: { enctype: "multipart/form-data" },
          body: formData,
        });
        if (response.ok) {
          const result = await response.json();
          // Reset form after successful submission
          clearElement();
          notifySuccess(MESSAGE_SUCCESS);
          navigate("/home");
        } else {
          notifyError(response.statusText);
        }
      } catch (error) {
        const { message } = error;
        notifyError(message ?? MESSAGE_ERROR);
      } finally {
        setPosting(false);
      }
    }
  };

  // エレメントをクリアする
  const clearElement = () => {
    setGameId("");
    setDrpDepositType("");
    setDrpGameType("");
    setErrors("");
    setFileUploader(null);
    setTxtAmount("");
    setTxtSpecialRemarks("");
    setPreview(null);
    setData({});
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleTxtBalanceAmount = (value) => {
    // 金額設定
    setBalanceAmount(value);
    // エラー変数を生成
    var error = "";
    if (!value) {
      // エラーを付与
      error = errorBalanceAmount;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, balanceAmount: error }));
  };

  const handleTxtRejectReasonChange = (event) => {
    // 金額設定
    setTxtRejectReason(event.target.value);

    // エラー変数を生成
    var error = "";
    if (!event.target.value) {
      // エラーを付与s
      error = errorRejectReason;
    }
    // エラーアレイに追加
    setErrors((prevErrors) => ({ ...prevErrors, rejectReason: error }));
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(gameId);
      alert("Game Id is copied");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const openWhatsApp = (telNo) => {
    if ((user?.userLevel ?? 2) === 2) {
      telNo = CommonWhatsAppNumber;
    }

    if (telNo !== "")
      window.open(`${WHATSAPP_URL}+94${telNo.trim()}`, "_blank");
    else
      notifyInfo(
        "Invalid Telephone Number, Please check the customer telephone Number"
      );
  };

  // プレビューできるファイルタイプ
  const fileTypes = [".png", ".jpg", ".ico", ".bmp", "jpeg"];

  if (loading) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-success" role="alert">
              Data is loading....
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-danger" role="alert">
              Unexpected error....
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="container pb-1 ">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12">
            <div className="alert alert-info" role="alert">
              Invalid user, Please log again
            </div>
          </div>
        </div>
      </div>
    );
  }

  // 戻り値
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="container body round-edge button-border">
          <div className="row text-left ">
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <SubHeader title="Money Deposit"></SubHeader>
            </div>
            <div className="row m-0 p-0">
              <div className="col-6 col-xs-6 col-sm-6 col-md-6 bg-customerId">
                <span className="font-b-16 color-black">Telephone No : </span>
                <span className="font-b-16 color-black">
                  {data?.telephoneNo?? user?.telephoneNo}
                </span>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-6 bg-customerId">
                <span className="font-b-16 color-black">Status : </span>
                <span className="font-b-16 color-black">
                  {depositStatus[data.depositStatus]}
                </span>
              </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
              <span className="font-b-16 color-black">Game Id</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
              <div className="row p-0 m-0">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 p-0 m-0">
                  <input
                    type="text"
                    className="font-r-16 width-full form-control"
                    value={data.gameId == undefined ? gameId : data.gameId}
                    onChange={handleTxtGameIdChange}
                    disabled={disabled}
                  />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 pl-1 m-0">
                  <div className="row m-0 p-0">
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 p-0 m-0">
                    <button
                      type="button"
                      className="btn btn-outline-warning p-1 font-r-14 "
                      onClick={copyToClipboard}
                    >
                      <img
                        src={imgCopy}
                        alt=""
                        style={{ verticalAlign: "1px", width: "24px" }}
                      />
                      <span
                        style={{
                          verticalAlign: "super",
                          paddingLeft: "10px",
                        }}
                      >
                        Copy
                      </span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-warning p-1 font-r-14 ms-1 "
                      onClick={() => openWhatsApp(data?.telephoneNo ?? user?.telephoneNo ?? "")}
                    >
                      <img
                        src={imgChat}
                        alt=""
                        style={{ verticalAlign: "1px", width: "24px" }}
                      />
                      <span
                        style={{
                          verticalAlign: "super",
                          paddingLeft: "10px",
                        }}
                      >
                        Chat
                      </span>
                    </button>
                  </div>
                  </div>
                </div>
              </div>
              <div className="font-r-12 color-error">
                {errors.gameId && <span>{errors.gameId}</span>}
              </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
              <span className="font-b-16 color-black">Game Type</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <select
                id="drpgameType"
                className="form-select font-r-16 width-half py-1"
                value={data.gameType == undefined ? drpGameType : data.gameType}
                onChange={handleDropdownGameChange}
                disabled={disabled}
              >
                <option value="">Select an option</option>
                {gameType.map((item, index) => (
                  <option value={item.key}>{item.value}</option>
                ))}
              </select>
              <div className="font-r-12 color-error">
                {errors.drpGameType && <span>{errors.drpGameType}</span>}
              </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 pt-1">
              <span className="font-b-16 color-black">Deposit Type</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <select
                id="drpdepositType"
                className="form-select font-r-16 width-half py-1"
                value={
                  data.depositType == undefined
                    ? drpDepositType
                    : data.depositType
                }
                onChange={handleDropdownChange}
                disabled={disabled}
              >
                <option value="">Select an option</option>
                {depositTypes.map((item, index) => (
                  <option value={item.key}>{item.value}</option>
                ))}
              </select>
              <div className="font-r-12 color-error">
                {errors.drpDepositType && <span>{errors.drpDepositType}</span>}
              </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <span className="font-b-16 color-black">Amount</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <CurrencyInput
                placeholder=""
                decimalsLimit={2}
                onValueChange={handleTxtAmountChange}
                prefix="$"
                className="font-r-16 width-half form-control text-right"
                value={data.amount == undefined ? txtAmount : data.amount}
                disabled={disabled}
              />
            </div>
            <div className="font-r-12 color-error">
              {errors.txtAmount && <span>{errors.txtAmount}</span>}
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <span className="font-b-16 color-black">Special Notes</span>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <textarea
                id="txtSpecialRemarks"
                value={
                  data.specialRemark == undefined
                    ? txtSpecialRemarks
                    : data.specialRemark
                }
                onChange={handleTxtSpecialRemarksChange}
                placeholder=""
                rows={4}
                className="width-full form-control"
                disabled={disabled}
              />
              {errors.multiLineText && <span>{errors.multiLineText}</span>}
            </div>
            {disabled == true ? (
              <div className="mb-2"></div>
            ) : (
              <>
                <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                  <span className="font-b-16 color-black">Attachment</span>
                </div>

                <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input form-control"
                        onChange={handleFileUploaderChange}
                        disabled={disabled}
                      />
                      <div className="font-r-12 color-error">
                        {errors.fileUploader && (
                          <span>{errors.fileUploader}</span>
                        )}
                      </div>
                      {preview &&
                        fileTypes.findIndex(
                          (item) => item === fileUploader.name.substr(-4)
                        ) !== -1 && <img src={preview} alt={""} width="100" />}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row">
              <div className="col-4 col-xs-4 col-sm-4 col-md-4 pb-1">
                {data.depositStatus === 1 ? (
                  <SubmitButton
                    id="btnVerify"
                    caption="Verify"
                    buttonType="button"
                    color="btn-outline-success"
                    onClick={handleVerify}
                    disabled={posting}
                  ></SubmitButton>
                ) : data.depositStatus == undefined ? (
                  <SubmitButton
                    id="btnSave"
                    caption="Save"
                    buttonType="submit"
                    color="btn-outline-warning"
                  ></SubmitButton>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-4 col-xs-4 col-sm-4 col-md-4 pb-1">
                {data.depositStatus >= 1 ? (
                  <></>
                ) : (
                  <SubmitButton
                    id="btnCancel"
                    caption="Clear"
                    buttonType="reset"
                    color="btn-outline-primary"
                    onClick={clearElement}
                    disabled={posting}
                  ></SubmitButton>
                )}
              </div>
              <div className="col-4 col-xs-4 col-sm-4 col-md-4 pb-1">
                {disabled == true ? (
                  <SubmitButton
                    id="btnDownload"
                    caption="Download"
                    buttonType="button"
                    color="btn-outline-success"
                    onClick={handleDownload}
                    disabled={posting}
                  ></SubmitButton>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* deposit section  */}
      {data.depositStatus >= 2 ? (
        <div className="container body round-edge button-border bg-accepted mt-2">
          <form onSubmit={handleCompleteSubmit}>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12">
              <div className="row text-left pb-2">
                <div className="col-3 col-xs-3 col-sm-3 col-md-3">
                  <span className="font-b-18 color-black">Deposit </span>
                </div>
                <div className="col-4 col-xs-4 col-sm-4 col-md-4">
                  <label className="font-b-18 color-black">
                    <input
                      type="radio"
                      value="2"
                      checked={selectedOption == 2}
                      onChange={handleOptionChange}
                      disabled={receiptDisabled}
                    />
                    &nbsp;&nbsp;Done
                  </label>
                </div>
                <div className="col-4 col-xs-4 col-sm-4 col-md-4">
                  <label className="font-b-18 color-black">
                    <input
                      type="radio"
                      value="3"
                      checked={selectedOption == 3}
                      onChange={handleOptionChange}
                      disabled={receiptDisabled}
                    />
                    &nbsp;&nbsp;Reject
                  </label>
                </div>
              </div>
            </div>
            <div className="row text-left  pb-2">
              {selectedOption == 2 && (
                <>
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                    <span className="font-b-16 color-black">
                      Balance Amount
                    </span>
                  </div>
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                    <CurrencyInput
                      placeholder=""
                      decimalsLimit={2}
                      onValueChange={handleTxtBalanceAmount}
                      prefix="$"
                      className="font-r-16 width-half form-control text-right"
                      value={
                        data.balanceAmount == undefined
                          ? balanceAmount
                          : data.balanceAmount
                      }
                      disabled={receiptDisabled}
                    />
                    <div className="font-r-12 color-error">
                      {errors.balanceAmount && (
                        <span>{errors.balanceAmount}</span>
                      )}
                    </div>
                  </div>
                  {receiptDisabled === true ? (
                    <></>
                  ) : (
                    <>
                      <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                        <span className="font-b-16 color-black">
                          Deposit Receipt
                        </span>
                      </div>
                      <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                        <div className="input-group mb-3">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input form-control mb-1"
                              onChange={handleReceiptUploaderChange}
                            />
                            <div className="font-r-12 color-error">
                              {errors.receiptUploader && (
                                <span>{errors.receiptUploader}</span>
                              )}
                            </div>
                            {receiptPreview &&
                              fileTypes.findIndex(
                                (item) =>
                                  item === receiptUploader.name.substr(-4)
                              ) !== -1 && (
                                <img
                                  src={receiptPreview}
                                  alt={""}
                                  width="100"
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {selectedOption == 3 && (
                <>
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                    <span className="font-b-16 color-black">Reject Reason</span>
                  </div>
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                    <textarea
                      onChange={handleTxtRejectReasonChange}
                      placeholder=""
                      rows={4}
                      className="width-full form-control"
                      disabled={receiptDisabled}
                      value={
                        data.rejectedRemark == undefined
                          ? txtRejectReason
                          : data.rejectedRemark
                      }
                    />
                    <div className="font-r-12 color-error">
                      {errors.rejectReason && (
                        <span>{errors.rejectReason}</span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {receiptDisabled === true ? (
              <div className="col-4 col-xs-4 col-sm-4 col-md-4 pb-1">
                {selectedOption === 2 && (
                  <SubmitButton
                    id="btnReceiptDownload"
                    caption="Receipt Download"
                    buttonType="button"
                    color="btn-outline-success"
                    onClick={handleReceiptDownload}
                    disabled={posting}
                  ></SubmitButton>
                )}
              </div>
            ) : (
              <div className="row">
                <div className="col-4 col-xs-4 col-sm-4 col-md-4 pb-1">
                  {selectedOption == 2 && (
                    <SubmitButton
                      id="btnComplete"
                      caption="Complete"
                      buttonType="submit"
                      color="btn-outline-success"
                      disabled={posting}
                    ></SubmitButton>
                  )}
                  {selectedOption == 3 && (
                    <SubmitButton
                      id="btnReject"
                      caption="Reject"
                      buttonType="submit"
                      color="btn-outline-danger"
                      disabled={posting}
                    ></SubmitButton>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Deposit;
