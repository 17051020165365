import React, { useEffect, createContext } from "react";
import "./App.css";
import Home from "./components/Home";
import Headernav from "./components/Headernav";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/common.css";
import Deposit from "./components/Deposit";
import {
  BrowserRouter as Router,  
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import DepositList from "./components/DepositList";
import User from "./components/User";
import CreateUserLink from "./components/User/CreateUserLink";
import Registration from "./components/User/Registration";
import Login from "./components/Login/Login";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "./components/Toaster";
import Withdraw from "./components/withdraw/Withdraw";
import WithdrawList from "./components/withdraw/WithdrawList";
import io from "socket.io-client";
import { socketUrl } from "./common/const";
import BannerCarousel from "./components/banners/BannerCarousel";
import Footer from "./components/Footer";
import WithdrawCompletingList from "./components/withdraw/WithdrawCompletingList";
import UserList from "./components/User/UserList";
import ResetPassword from "./components/User/ResetPassword";

// Create a context for socket
//export const SocketContext = createContext();

function AppContent() {
  const location = useLocation();

  // when registration page is opening, heder is not showing
  var showHeader = location.pathname !== "/registration";
  if (showHeader === true) {
    showHeader = location.pathname !== "/";
  }
  if (showHeader === true) {
    showHeader = location.pathname !== "/login";
  }
  if (showHeader === true) {
    showHeader = !location.pathname.includes("/reset-password");
  }

  return (
    <>
      <Toaster />
      <header className="App-header">
        <div className="container pb-1">
          <div className="row">
            <div className="col-12 col-md-12">
              {showHeader === true ? (
                <Headernav />
              ) : (
                <div style={{ height: "10vh" }}> </div>
              )}
            </div>
          </div>
        </div>
        <div className="container pb-1">
          <div className="row">
            <div className="col-12 col-md-12">
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/home" element={<Home />} />
                <Route path="/deposit" element={<Deposit />} />
                <Route path="/depositList" element={<DepositList />} />
                <Route path="/user" element={<User />} />
                <Route path="/userlink" element={<CreateUserLink />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/login" element={<Login />} />
                <Route path="/withdraw" element={<Withdraw />} />
                <Route path="/withdrawList" element={<WithdrawList />} />
                <Route path="/banner" element={<BannerCarousel />} />
                <Route
                  path="/withdrawcompleting"
                  element={<WithdrawCompletingList />}
                />
                <Route path="/userList" element={<UserList />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
              </Routes>
            </div>
          </div>
        </div>
      </header>
      <div className="container pb-1">
        {showHeader === true ? <Footer></Footer> : <></>}
      </div>
    </>
  );
}

const App = () => {

 

  // useEffect(() => {

  //   const socket = io(socketUrl, {
  //     transports: ["websocket", "polling"], // Fallback to polling if WebSocket fails
  //     withCredentials: true, // Allow credentials to be sent
  //   });
    
  //   socket.on("connect", () => {
  //     console.log("Connected to Socket.io server");
  //   });

  //   socket.on("message", (data) => {
  //     console.log(data);
  //   });

  //   socket.on("disconnect", () => {
  //     console.log("Disconnected from Socket.io server");
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  return (
    //  <SocketContext.Provider value={socket}>
    <Router>
      <AppContent></AppContent>
    </Router>
    // </SocketContext.Provider>
  );
};
export default App;
