import React, { useContext } from "react";
import "./common.css";
import { depositStatusColor, depositTypes } from "../common/const";
import { useNavigate } from "react-router-dom";
import { dateFormat, depositStatus } from "../common/const";
import { UserContext } from "../context/UserContext";
import { format } from 'date-fns';

const GridList = ({ color, font, item, index }) => {
  const Class = `round-edge header m-1 width-full white-border ${color} ${font}`;

  //////////////USER ///////////////////
  const { user } = useContext(UserContext);
  //////////////USER ///////////////////

  // ナヴィゲーションを生成
  const navigate = useNavigate();

  var badgeClass = "position-sticky start-100 transform-badge badge";
  var badgetext = "";
  var updatedDatetxt = "";

  if (item.depositStatus === 1) {
    badgeClass = badgeClass + " bg-new";
  } else if (item.depositStatus === 2) {
    badgeClass = badgeClass + " bg-done";
    updatedDatetxt = `${badgetext} date: ${format(item.saveDate,dateFormat)}`;
  } else if (item.depositStatus === 3) {
    badgeClass = badgeClass + " bg-reject";
    updatedDatetxt = `${badgetext} date: ${format(item.saveDate,dateFormat)}`;
  } else if (item.depositStatus === 4) {
    badgeClass = badgeClass + " bg-verify";
  }

  const depositedDateformated = format(item.depositedDate,dateFormat);


  var TypeName = depositTypes.find((x) => x.key == item.depositType);
  if (!TypeName) TypeName = { value: "invalid Type" };

  const handleOnclick = async (e) => {
    // redirect to the deposit form with selected deposit id/ customer id / user
    navigate(`/deposit`, {
      state: { id: item._id, userId: item.userId, currentUser: user },
    });
  };
  return (
    <button className={Class} onClick={handleOnclick} id={`btn_${index}`}>
      <div className="row bg-header round-topedge">
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 d-flex min-hight-25">
          <span className="font-b-14 text-left">
            {`Game Id: ${item.gameId ?? ""}`}
          </span>
          <span className="font-b-14 text-left" style={{paddingLeft:"100px"}}>
            {`Telephone No: ${user.telephoneNo ?? ""}`}
          </span>
          <span
            className={`position-sticky start-100 transform-badge badge with-100px  ${
              depositStatusColor[item.depositStatus]
            }`} style={{maxHeight:'26px', lineHeight:'13px'}}
          >
            {depositStatus[item.depositStatus]}
          </span>
        </div>
      </div>
      <div className={`row ${item.isRead == 0 ? 'unread': ''}`}>
        <div className="col-6 col-xs-6 col-sm-6 col-md-6 d-flex">
          <p className="font-r-14 text-left m-0">{`Amount: ${
            item.amount == undefined ? "" : item.amount
          }`}</p>
        </div>
        <div className="col-6 col-xs-6 col-sm-6 col-md-6 d-flex">
          <p className="font-r-14 text-left m-0">{`Type: ${
            TypeName.value == undefined ? "" : TypeName.value
          }`}</p>
        </div>
      </div>
      <div  className={`row ${item.isRead == 0 ? 'unread': ''}`}>
        <div className="col-6 col-xs-6 col-sm-6 col-md-6 d-flex">
          <p className="font-r-14 text-left m-0">{`dep. Date: ${
            item.depositedDate == undefined ? "" : depositedDateformated
          }`}</p>
        </div>
        <div className="col-6 col-xs-6 col-sm-6 col-md-6 d-flex">
          <p className="font-r-14 text-left m-0">{updatedDatetxt}</p>
        </div>
      </div>
      <div  className={`row ${item.isRead == 0 ? 'unread': ''}`}>
        <div className="col-12 col-xs-12 col-sm-12 col-md-12 d-flex">
          <p className="font-r-14 text-left m-0">{`Special Remark: ${
            item.specialRemark == undefined ? "" : item.specialRemark
          }`}</p>
        </div>
      </div>
    </button>
  );
};

export default GridList;
